import { ReferenceInput, AutocompleteInput, required, useGetOne, NumberInput, useRecordContext, SelectInput, TextInput } from "react-admin";
import { useWatch } from 'react-hook-form';
import CreateDialog from "src/components/CreateDialog";
import formatCurrency from "src/utilities/formatCurrency";
import { DepartmentInput, CategoryInput } from "../services/DepartmentInputs";


type ServiceInputFilter = {
  repairable_id?: string;
  is_enabled: true;
  service: {
    department?: string;
    category?: string;
  }
}

const ServiceInput = ({ defaultApparelId }: { defaultApparelId: string }) => {
  const repairable_id: string = useWatch({ name: 'repairable_id', defaultValue: defaultApparelId });
  const department: string = useWatch({ name: 'department' });
  const category: string = useWatch({ name: 'category' });
  const filter: ServiceInputFilter = {
    is_enabled: true,
    service: {}
  };
  if (repairable_id) filter.repairable_id = repairable_id;
  if (department) filter.service.department = department;
  if (category) filter.service.category = category;
  return (
    <ReferenceInput label="Service" source="rate_id" reference="rates" filter={filter}>
      <AutocompleteInput
        optionValue="id"
        optionText={
          (record) => `${record.service.name} - ${record.repairable.name} - ${formatCurrency(record.price_override || record.service.default_price)}`
        }
        helperText="Make sure you click the desired service from the dropdown list!"
        fullWidth
        validate={required()}
      />
    </ReferenceInput>
  )
}

const PriceInput = () => {
  const rateId: string = useWatch({ name: 'rate_id' });
  const { data: rate } = useGetOne('rates', { id: rateId });
  let defaultValue: number | undefined;
  if (rate) {
    defaultValue = rate.price_override || rate.service.default_price;
  }
  return (
    <NumberInput source="price" fullWidth helperText={false} defaultValue={defaultValue} />
  )
}

export default function AddQuoteDialog() {
  const record = useRecordContext();
  return (
    <CreateDialog resource="quotes" defaultValues={{ item_id: record.id }} label="Add quote" description="Choose a repair service to perform." buttonColor="secondary">
      <ReferenceInput label="Item Type" source="repairable_id" reference="repairables" perPage={50}>
        <SelectInput optionValue="id" optionText="name" defaultValue={record.repairable_id} helperText="Further filter service options by choosing an item type." fullWidth resettable />
      </ReferenceInput>
      <DepartmentInput helperText={false} fullWidth resettable validate={required()} />
      <CategoryInput helperText={false} fullWidth resettable validate={required()} />
      <ServiceInput defaultApparelId={record.repairable_id} />
      <PriceInput />
      <TextInput label="Service request" source="note" helperText={false} fullWidth multiline />
      <TextInput label="Memo" source="internal_note" helperText={false} fullWidth multiline />
    </CreateDialog>
  )
}
