import React from 'react'
import {
  Edit as AdminEdit,
  EditProps,
  useRecordContext,
  useEditContext
} from 'react-admin'
import ItemDetails from 'src/components/resources/order_items/ItemDetails';
import Card from '@mui/material/Card';

const ItemContext = () => {
  const item = useRecordContext();
  return <ItemDetails order={item.order} item={item} />
}

const Edit = (props: EditProps) => {
  const Title = () => {
    const item = useRecordContext();
    const { isLoading } = useEditContext();
    if (isLoading) return null;
    return (
      <span>
        #<strong>{item.code} &ndash; {item.repairable_name}</strong>
      </span>
    )
  }
  return (
    <AdminEdit title={<Title />} {...props}>
      <Card sx={{ p: 2 }}>
        <ItemContext />
      </Card>
    </AdminEdit>
  )
}

export default Edit;
