import * as React from 'react';
import { FunctionField, FieldProps, useRecordContext } from 'react-admin';
import { Chip } from '@mui/material';
import get from 'lodash/get'; // Use lodash's get function for safe deep access

const StatusField = ({ source = 'status', ...props }: FieldProps & { source?: string }) => {
  const record = useRecordContext();
  const statusPath = source; // Default to 'status' if source is not provided
  const status = get(record, statusPath);
  const inactiveStatuses = ['pending_receipt', 'returned', 'canceled'];
  const variant = inactiveStatuses.includes(status) ? 'outlined' : 'filled';

  return (
    <FunctionField
      {...props}
      render={() => (
        <Chip label={status?.titleize() ?? ''} color="secondary" size="small" variant={variant} />
      )} />
  );
}

export default StatusField;
