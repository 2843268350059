import React, { useState } from "react";
import {
  TextInput,
  Button,
  useNotify,
  FormDataConsumer,
  useDataProvider,
} from "react-admin";
import StateCodeInput from "src/components/StateCodeInput";
import { useFormContext } from 'react-hook-form';
import { Grid } from "@mui/material";

interface IGeocodeInputProps {
  source: string;
  onGeocode: Function;
}

const GeocodeButton = (props: IGeocodeInputProps) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  const getValue = (_formData: any, key: string) => {
    return eval(`_formData.${props.source}.${key}`)
  }

  return (
    <FormDataConsumer>
      {({ formData }) => {
        const handleClick = async () => {
          const address = [
            getValue(formData, 'street'),
            getValue(formData, 'city'),
            getValue(formData, 'state_code'),
            getValue(formData, 'postal_code')
          ]
            .filter((x) => x)
            .join(", ");

          setLoading(true);
          try {
            const response = await dataProvider.geocode("addresses", address);
            //@ts-ignore
            props.onGeocode(response.data.results[0]);
          } catch (e: any) {
            console.error(e)
            notify(
              "There was an error while geocoding. Please review your address and try again.",
              { type: "error" }
            );
          } finally {
            setLoading(false);
          }
        };

        return (
          <Button
            variant="outlined"
            size="large"
            label="Geocode"
            onClick={handleClick}
            disabled={loading}
            fullWidth
          />
        );
      }}
    </FormDataConsumer>
  );
};

const GeocodeInput = (props: { source: string }) => {
  const form = useFormContext()
  const getSource = (attr: string) => `${props.source}.${attr}`
  return (
    <>
      <Grid container columnSpacing={2} rowSpacing={0}>
        <Grid item xs={12}>
          <TextInput label="Address Line 1" source={getSource("street")} helperText={false} fullWidth sx={{ mb: 1 }} required={true} autoComplete="off" />
          <TextInput label="Address Line 2" source={getSource("address_line2")} helperText={false} fullWidth sx={{ mb: 1 }} />
          <TextInput label="City" source={getSource("city")} helperText={false} required={true} fullWidth sx={{ mb: 1 }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <StateCodeInput source={getSource("state_code")} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            label="ZIP Code"
            source={getSource("postal_code")}
            inputMode="numeric"
            // pattern="^\d{5}(?:[- ]?\d{4})?$"
            fullWidth
            helperText={false}
            sx={{ mb: 2 }}
          />
        </Grid>
      </Grid >
      <TextInput source={getSource("mapbox_id")} label={false} helperText={false} fullWidth disabled sx={{ display: 'none' }} />
      <GeocodeButton
        source={props.source}
        onGeocode={(result: any) => {
          form.setValue(getSource('street'), result.local_part)
          form.setValue(getSource('mapbox_id'), result.mapbox_id)
          form.setValue(getSource('city'), result.city)
          form.setValue(getSource('postal_code'), result.postal_code)
          form.setValue(getSource('state_code'), result.state_code)
        }}
      />
    </>
  );
};

GeocodeInput.displayName = "GeocodeInput";

export default GeocodeInput;
