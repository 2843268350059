// in posts.js
import React from 'react'
import { Edit as AdminEdit, EditProps, NumberInput, SimpleForm, TextInput } from 'react-admin'

const Edit = (props: EditProps) => {
  const Title = () => <span>POS Repair Service</span>
  return (
    <AdminEdit title={<Title />} {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <NumberInput source="default_price" />
        <TextInput source="description" rows={5} style={{ width: '100%' }} multiline />
      </SimpleForm>
    </AdminEdit>
  )
}

export default Edit
