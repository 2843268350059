import * as React from 'react';
import { SaveButton, Form, useNotify, useRefresh, useUpdate, DeleteWithConfirmButton } from 'react-admin';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { MuiButtonVariants, MuiColors, MuiSizes } from 'types';

type FormDialogProps = {
  resource: string;
  record: any;
  title?: string;
  label: JSX.Element | string;
  buttonSize?: MuiSizes;
  buttonVariant?: MuiButtonVariants;
  canDelete?: boolean;
  buttonColor?: MuiColors;
  description?: string;
  children: React.ReactNode;
}

export default function EditDialog(props: FormDialogProps) {
  const [isOpen, toggleOpen] = React.useState(false);

  const refresh = useRefresh();
  const notify = useNotify();
  const [update] = useUpdate(props.resource, props.record.id);

  const onSuccess = () => {
    refresh();
    notify('Record updated successfully', { type: 'success' })
    toggleOpen(false)
  }

  const onError = (error: any) => {
    try {
      const errArray = Object.entries(error.body.errors)[0]
      // @ts-ignore
      const message = errArray[0].titleize() + ' ' + errArray[1][0]
      notify(`Error: ${message}.`, { type: 'error' })
    } catch (e) {
      console.error(e)
      notify('Error: Something went wrong. Contact a developer.', { type: 'error' })
    }
  }

  const handleSubmit = async (values: any) => {
    try {
      await update(props.resource, { id: props.record.id, data: values, previousData: props.record });
      setTimeout(() => onSuccess(), 250);
    } catch (error) {
      onError(error);
    }
  }

  const handleClose = () => toggleOpen(false)

  return (
    <>
      <Button size={props.buttonSize ? props.buttonSize : "small"} variant={props.buttonVariant} color={props.buttonColor} sx={{ minWidth: 'auto' }} onClick={() => toggleOpen(!isOpen)}>
        {props.label}
      </Button>
      {isOpen && <Dialog open={isOpen} onClose={handleClose}>
        <Form onSubmit={handleSubmit} record={props.record}>
          <DialogTitle>{props.title ? props.title : props.label}</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ mb: 2 }}>
              {props.description}
            </DialogContentText>
            {props.children}
          </DialogContent>
          <DialogActions>
            {props.canDelete && <DeleteWithConfirmButton record={props.record} confirmTitle={`Delete "${props.record.name}"`} />}
            <span style={{ flexGrow: 1 }} />
            <Button onClick={handleClose}>Cancel</Button>
            <SaveButton />
          </DialogActions>
        </Form>
      </Dialog>}
    </>
  );
}

EditDialog.defaultProps = {
  buttonVariant: 'contained',
  canDelete: false,
}
