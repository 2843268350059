import * as React from 'react';
import { useNotify, useRefresh } from 'ra-core';
import { useGetIdentity } from 'react-admin';
import { useForm, Controller, Control } from 'react-hook-form';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useDialog } from 'src/hooks/useDialog';
import dataProvider from 'src/providers/data';
import QualityControlMemoInput from 'src/inputs/orders/QualityControlMemoInput';

function ReasonInput ({ control }: {
  control: Control<{ reason: any; quality_control_memo: any; performed_by_id: any; }>
}) {
  const reasons = [
    'incorrect_color',
    'damaged_lining',
    'damaged_material',
    'faded_logo',
    'glue_residue',
    'dirty_hardware',
    'incomplete',
    'missing_foam_points',
    'missing_hardware',
    'missing_shoe_tree',
    'poor_quality_cleaning',
    'poor_quality_repair',
    'poor_quality_shine',
    'stitching_crooked',
    'sticking_innersole',
    'sticking_sole'
  ]
  return (
    <Controller
      name="reason"
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Select
          value={value}
          onChange={(event) => onChange(event.target.value)}
          error={!!error}
          displayEmpty
          fullWidth
          required
        >
          <MenuItem value="" disabled>
            Select rejection reason
          </MenuItem>
          {reasons.map(reason => (
            <MenuItem key={reason} value={reason}>
              {/* @ts-ignore */}
              <span>{reason.titleize()}</span>
            </MenuItem>
          ))}
        </Select>
      )}
    />
  )
}


const RejectQuoteDialog = ({ quote }: { quote: any }) => {
  const { identity } = useGetIdentity()
  const refresh = useRefresh() // order_items
  const defaultValues = React.useMemo(() => ({
    reason: quote.reason,
    quality_control_memo: quote.quality_control_memo,
    performed_by_id: quote.performed_by_id
  }), [quote]);
  const { toggleDialog, DialogComponent } = useDialog();
  const { handleSubmit, control } = useForm({ defaultValues });
  const notify = useNotify();
  const isRejected = quote.status === 'rejected';

  const onSubmit = async (data: any) => {
    try {
      await dataProvider.update('quotes', {
        id: quote.id,
        data: {
          ...data,
          assessed_at: new Date().toISOString(),
          assessed_by_id: identity?.id,
          performed_by_id: null,
          status: 'rejected'
        },
        previousData: defaultValues
      })
      notify(`Work rejected by ${identity?.fullName}`, { type: 'success' })
      refresh()
    } catch (error: any) {
      console.error(error)
      notify(`Error: ${error.message!}.`, { type: 'error' })
    } finally {
      toggleDialog()
    }
  };

  const handleToggleDialog = React.useCallback(() => {
    toggleDialog();
  }, [toggleDialog]);

  return (
    <>
      <Button size="small" variant={isRejected ? "outlined" : "contained"} color="error" sx={{ minWidth: 'auto' }} onClick={handleToggleDialog}>
        {isRejected ? 'Edit rejection' : 'Reject'}
      </Button>
      <DialogComponent>
        <DialogTitle>Reject: {quote.category.titleize()} - {quote.name}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Indicate the issue is with the work here.
          </DialogContentText>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ReasonInput control={control} />
            <QualityControlMemoInput control={control} />
            <DialogActions>
              <span style={{ flexGrow: 1 }} />
              <Button onClick={handleToggleDialog}>Cancel</Button>
              <Button type="submit" variant="contained" color="error">Reject</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </DialogComponent>
    </>
  )
}

export default RejectQuoteDialog
