import * as React from 'react';
import { TextInput, BooleanInput, ShowActionsProps, useRecordContext } from 'react-admin';
import EditDialog from 'src/components/EditDialog';

const Actions = () => {
  const record = useRecordContext();
  if (!record) return <></>;
  return (
    <div style={{ textAlign: 'right', marginTop: 10, marginBottom: 10 }}>
      <EditDialog resource="users" record={record} label="Edit memo" description="A descriptive note to assist staff during subsequent customer visits." buttonVariant="outlined">
        <TextInput source="internal_note" fullWidth multiline />
      </EditDialog>
      &nbsp;&nbsp;
      <EditDialog resource="users" record={record} label="Toggle VIP Status" description="Indicate whether customer is a VIP customer." buttonVariant="outlined">
        <BooleanInput label="Toggle VIP Status" source="is_vip" fullWidth />
      </EditDialog>
    </div>
  )
}

export default Actions;
