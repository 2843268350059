// in posts.js
import React from 'react'
import { Create as AdminCreate, SimpleForm, TextInput, SelectInput, NumberInput, SelectArrayInput, CreateProps } from 'react-admin'

const Create = (props: CreateProps) => (
  <AdminCreate {...props} title="Create POS Repair Service">
    <SimpleForm>
      <TextInput source="name" required />
      <TextInput type="text" source="description" />
      <NumberInput source="default_price" required />
      <SelectInput source="repairable_id" choices={[
        { id: 'bad08676-7af7-4877-aab1-7d52ef25cd9a', name: 'Belts' },
        { id: 'ffaccb2d-c9c4-44d3-8307-be3743342bb7', name: 'Garments' },
        { id: '3c1574ea-1860-499f-affb-34fe4eb54b42', name: 'Wallets' },
        { id: '0414fcc1-187c-479d-806a-e31d3788267b', name: 'Men\'s Boots' },
        { id: '2d3ca476-5ced-4f64-8085-91e29bb87dc5', name: 'Men\'s Shoes' },
        { id: '872406cc-1c2e-4202-8875-8665bdf0381f', name: 'Women\'s Boots' },
        { id: 'cad08b3c-5447-47b8-920a-73c1a6a4b719', name: 'Bags' },
        { id: '8cee18b2-7042-4fdc-8fb3-f570cc01bf56', name: 'Women\'s Shoes' },
        { id: '15d8e99f-2c38-439f-a558-4a064595102a', name: 'Sneaker' }
      ]} />
      <SelectArrayInput source="categories" choices={[
        { id: 'patching', name: 'Patching' },
        { id: 'stretching', name: 'Stretching' },
        { id: 'shining', name: 'Shining' },
        { id: 'cleaning', name: 'Cleaning' },
        { id: 'shipping', name: 'Shipping' },
      ]} />
    </SimpleForm>
  </AdminCreate>
)

export default Create
