import React from 'react';
import { Link } from 'react-admin';
import { Button } from '@mui/material';
import {
  useNotify
} from 'react-admin'
import env from 'src/env'
import { Display, useSortingContext } from 'src/providers/sortingScreen';

const ReturnMethod = ({ orderItem }: { orderItem: any }) => {
  let returnMethod;
  if (orderItem.order.corporate_account?.id) {
    returnMethod = 'CORPORATE'
  } else {
    switch (orderItem.order.return_method) {
      case 'ups_shipping': returnMethod = 'UPS'; break;
      case 'standard_courier': returnMethod = 'Delivery'; break;
      case 'golocker_dropoff': returnMethod = 'Golocker'; break;
      case 'store_pickup': returnMethod = `Store: ${orderItem.order.storefront.code}`; break;
      default: returnMethod = 'Unknown'; break;
    }
  }
  return <span style={{ fontSize: '4rem', fontWeight: 600 }}>{returnMethod}</span>;
}

const AssignBinButton = () => {
  const token = localStorage.getItem(env.TOKEN_KEY)
  const notify = useNotify()
  const [isSubmitting, setSubmitting] = React.useState(false)
  const [isAssigned, setAssignment] = React.useState(false)
  const { orderItem } = useSortingContext()

  const handleClick = async () => {
    setSubmitting(true)
    try {
      const response = await fetch(`${env.API_URL}/rest/order_items/${orderItem.id}/assign_bin`, {
        method: 'POST',
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      if (response.status >= 400) {
        const data = await response.json()
        const errArray = Object.entries(data.errors)[0]
        // @ts-ignore
        const error = errArray[0].titleize() + ' ' + errArray[1][0]
        notify(`Error: ${error}.`, { type: 'warning' })
      } else {
        notify('Successfully assigned bin')
        setAssignment(true)
      }
    } catch (error) {
      notify('Server error. Contact the developer', { type: 'error' })
      // TODO: log error in sentry
    } finally {
      setSubmitting(false)
    }
  }
  return isAssigned ? (
    <span style={{ fontSize: '1rem', ...boxStyles }}>
      Rescan to see bin assignment
    </span>
  ) : (
    <Button
      size="large"
      color="error"
      variant="contained"
      fullWidth
      onClick={handleClick}
      disabled={isSubmitting}
    >
      Assign Bin
    </Button>
  )
}

const FinishRepairButton = () => {
  const token = localStorage.getItem(env.TOKEN_KEY)
  const notify = useNotify()
  const [isSubmitting, setSubmitting] = React.useState(false)
  const { orderItem } = useSortingContext()

  const handleClick = async () => {
    setSubmitting(true)
    try {
      const response = await fetch(`${env.API_URL}/rest/orders/${orderItem.order_id}/finish_repair`, {
        method: 'POST',
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      if (response.status >= 400) {
        const data = await response.json()
        const errArray = Object.entries(data.errors)[0]
        // @ts-ignore
        const error = errArray[0].titleize() + ' ' + errArray[1][0]
        notify(`Error: ${error}.`, { type: 'warning' })
      } else {
        notify('Successfully completed order')
      }
    } catch (error) {
      notify('Server error. Contact the developer', { type: 'error' })
      // TODO: log error in sentry
    } finally {
      setSubmitting(false)
    }
  }
  return (
    <Button
      size="large"
      color="error"
      variant="contained"
      fullWidth
      onClick={handleClick}
      disabled={isSubmitting}
    >
      Finish Repair
    </Button>
  )
}

const messageBoxStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  textAlign: 'center',
  height: '100%',
  width: '100%',
  padding: '2em'
}

const buttonStyle: React.CSSProperties = {
  fontWeight: 600,
  textDecoration: 'none',
  borderWidth: 2,
  padding: '0.5em 1em',
  borderStyle: 'solid'
}

const boxStyles: React.CSSProperties = {
  fontWeight: 600,
  textDecoration: 'none',
  borderWidth: 2,
  borderStyle: 'solid'
}

// TODO: lots of re-renders here.
export default function SortingMessage (props: { display: Display }) {
  const { textColor } = props.display
  const { code, orderItem } = useSortingContext()
  const binnedItemCount = orderItem.order.items.filter((item: any) => !!item.bin_id).length;
  const totalItemCount = orderItem.order.items_count;
  const isSortable = code && ['item_complete', 'order_complete', 'canceled'].includes(code) // should be scanned.
  const isBinnable = code && isSortable && totalItemCount > 1 // gets assigned a bin.
  const isReadyStatus = ['canceled', 'approved'].includes(orderItem.order.status)
  const isReady = isSortable && isReadyStatus && (totalItemCount === binnedItemCount || totalItemCount === 1)
  const hasBin = !!orderItem.bin
  return (
    <div style={messageBoxStyle}>
      <span style={{ fontSize: '3rem', fontWeight: 600 }}>{orderItem.code}</span>
      {/* only show item count if the scanned item is complete. */}
      {props.display.showCount && (
        <span style={{ fontSize: '3rem', fontWeight: 600 }}>
          Item {binnedItemCount > 0 ? binnedItemCount : '1'} of {totalItemCount}
        </span>
      )}
      <span style={{ fontSize: '3rem', fontWeight: 600 }}>{props.display.message}</span>
      {isBinnable ?
        hasBin ? (
          <span style={{ fontSize: '4rem', ...boxStyles, color: textColor, borderColor: textColor }}>
            Bin {orderItem.bin.number.toLocaleString('en-US', {
              minimumIntegerDigits: 3,
              useGrouping: false
            })}
          </span>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <span style={{ fontSize: '2rem', ...boxStyles, color: textColor, borderColor: textColor }}>
              Error: Missing Bin!
            </span>
            <AssignBinButton />
          </div>
        )
      : <></>}
      {isSortable && <ReturnMethod orderItem={orderItem} />}
      {isReady && <FinishRepairButton />}
      {orderItem.id && (
        <Link to={`/order_items/${orderItem.id}`} target="_blank" style={{ ...buttonStyle, color: textColor, borderColor: textColor }}>
          Review Order Item
        </Link>
      )}
    </div>
  );
}
