import { Dialog } from '@mui/material'
import { useState, PropsWithChildren, HTMLAttributes } from 'react'

interface DialogProps extends HTMLAttributes<HTMLDivElement> { }

export function useDialog(initialIsOpen = false) {
  const [isOpen, setIsOpen] = useState(initialIsOpen)

  function closeDialog() {
    setIsOpen(false)
  }

  function toggleDialog() {
    setIsOpen(prev => !prev)
  }

  const DialogComponent: React.FC<DialogProps> = (props: PropsWithChildren<DialogProps>) => (
    <Dialog open={isOpen} onClose={closeDialog}>
      {props.children}
    </Dialog>
  )

  return { toggleDialog, DialogComponent }
}
