function isPlainObject(a) {
  return (!!a) && (a.constructor === Object);
}

function merge(target) {
  let to = { ...target };

  for (let index = 1; index < arguments.length; index++) {
    let nextSource = arguments[index];

    if (nextSource !== null && nextSource !== undefined) {
      for (let nextKey in nextSource) {
        // Avoid bugs when hasOwnProperty is shadowed
        if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
          if (isPlainObject(to[nextKey]) && isPlainObject(nextSource[nextKey])) {
            to[nextKey] = merge(to[nextKey], nextSource[nextKey]);
          } else if (Array.isArray(nextSource[nextKey])) {
            // Ensure distinct keys in array
            const set = new Set(nextSource[nextKey]);
            to[nextKey] = Array.from(set);
          } else {
            to[nextKey] = nextSource[nextKey];
          }
        }
      }
    }
  }
  return to;
}

export default merge;
