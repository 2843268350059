//@ts-nocheck
import * as React from 'react'
import { TextFieldProps } from 'react-admin'
import get from 'lodash/get'
import Typography from '@mui/material/Typography'

const MonetaryField = ({ className, source, record = {}, emptyText, ...rest }: TextFieldProps) => {
  const value = get(record, source)
  return (
    <Typography component="span" variant="body2" className={className} {...rest}>
      {value ? `$${value.toFixed(2)}` : emptyText}
    </Typography>
  )
}

MonetaryField.defaultProps = {
  addLabel: true
}

export default MonetaryField
