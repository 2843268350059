import React from "react";
import {
  FunctionField,
  Datagrid,
  DateField,
  TextField,
  Edit as AdminEdit,
  SimpleForm,
  ReferenceField,
  ReferenceManyField,
  NumberField,
  TextInput,
  NumberInput,
  EditProps,
  RaRecord,
  useRecordContext,
  useEditContext
} from "react-admin";
import { format } from "date-fns";
import { Done, Clear } from "@mui/icons-material";

const Edit = (props: EditProps) => {
  const Title = () => {
    const record = useRecordContext();
    const { isLoading } = useEditContext();
    if (isLoading) return <></>;
    return <span>{record.first_name} {record.last_name}</span>
  }
  return (
    <AdminEdit title={<Title />} {...props}>
      <SimpleForm>
        <TextField source="name" />
        <FunctionField label="VIP?" render={(record: RaRecord) => record.is_vip ? <Done color="error" /> : <Clear color="disabled" />} />
        <TextField source="email" />
        <TextField source="phone_number" />
        <TextInput style={{ width: '50%' }} source="internal_note" multiline rows={5} />

        <NumberInput
          source="credit_amount"
        />
        <DateField source="created_at" />

        <ReferenceManyField
          label="Orders"
          reference="orders"
          target="user_id"
          sort={{ field: "latest_status_at", order: 'DESC' }}
        >
          <Datagrid>
            <ReferenceField
              label="Ticket #"
              source="id"
              reference="orders"
              link={"edit"}
            >
              <TextField source="confirmation_code" />
            </ReferenceField>
            <TextField source="storefront.name" label="Storefront" />
            <FunctionField
              label="Receipt"
              render={(record: RaRecord) => record.receive_method.titleize()}
            />
            <FunctionField
              label="Return"
              render={(record: RaRecord) => record.return_method.titleize()}
            />
            <FunctionField
              label="Status"
              render={(record: RaRecord) => {
                let date;
                switch (record.status) {
                  case "pending_receipt":
                    date = record.created_at;
                    break;
                  case "received":
                    date = record.received_at;
                    break;
                  case "invoiced":
                    date = record.invoiced_at;
                    break;
                  case "approved":
                    date = record.approved_at;
                    break;
                  case "repaired":
                    date = record.completed_at;
                    break;
                  default:
                    date = null;
                }
                if (date) {
                  return `${record.status.titleize()} Since ${format(
                    new Date(date),
                    "MMM d, yyyy"
                  )}`;
                } else {
                  return record.status.titleize();
                }
              }}
            />
            <NumberField
              source="price"
              locales="en-US"
              options={{ style: "currency", currency: "USD" }}
            />
            <FunctionField
              label="Has note"
              render={(record: RaRecord) =>
                record.customer_note ? (
                  <Done color="error" />
                ) : (
                  <Clear color="disabled" />
                )
              }
            />
            <FunctionField
              label="Is rush"
              render={(record: RaRecord) =>
                record.rush_fee ? (
                  <Done color="error" />
                ) : (
                  <Clear color="disabled" />
                )
              }
            />
          </Datagrid>
        </ReferenceManyField>
      </SimpleForm>
    </AdminEdit>
  );
};

export default Edit;
