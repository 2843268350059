import * as React from "react";
import { maxLength, minLength, TextInput } from "react-admin";
import { useController, useFormContext } from 'react-hook-form'

function formatPhoneNumber(value: string) {
  const phone = value.replace(/\D/g, ''); // Remove non-numeric characters
  const formatted = phone
    .replace(/^(\d{1})(\d{3})(\d{3})(\d{4})$/, '+$1 $2 $3 $4');
  return formatted;
}

export default function PhoneNumberInput(props: { source: string }) {
  const { control } = useFormContext();
  const {
    field: { value, onChange }
  } = useController({ name: 'phone_number', control });

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedValue = formatPhoneNumber(e.target.value);
    onChange(formattedValue); // Set the formatted value to the form state
  };

  return (
    <TextInput
      source={props.source}
      label="Phone Number"
      sx={{ mb: 1 }}
      required={true}
      helperText={false}
      fullWidth
      defaultValue=""
      value={value}
      parse={formatPhoneNumber}
      format={formatPhoneNumber}
      validate={[
        minLength(15, "Phone number must be in the format '+1 111 111 1111'"),
        maxLength(15, "Phone number must be in the format '+1 111 111 1111'")
      ]}
      onChange={handlePhoneChange} // Use custom onChange to format phone number
    />
  );
}
