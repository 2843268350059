import * as React from 'react';
import {
  Step,
  StepLabel,
  StepContent,
  SxProps,
  Theme,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

type PendingStepProps = {
  label: string;
  children: React.ReactElement;
  sx?: SxProps<Theme>;
}

const PendingStep = (props: PendingStepProps) => {
  return (
    <Step expanded active index={0} sx={{ mt: 2, ...props.sx }}>
      <StepLabel icon={<AccessTimeIcon fontSize="small" color="warning" />}>
        {props.label}
      </StepLabel>
      <StepContent>
        {props.children}
      </StepContent>
    </Step>
  )
}

export default PendingStep;
