
import React from "react";
import { Grid } from "@mui/material";
import { Create as AdminCreate, CreateProps, SimpleForm, TextInput, useGetIdentity } from "react-admin";
import GeocodeInput from "src/components/GeocodeInput";
import PhoneNumberInput from "src/inputs/PhoneNumberInput";

const Create = (props: CreateProps) => {
  const { identity } = useGetIdentity()
  return (
    <AdminCreate>
      <SimpleForm>
        <Grid container columnSpacing={2} rowSpacing={0}>
          <Grid item xs={12} md={6}>
            <TextInput source="first_name" label="First Name" sx={{ mb: 1 }} required={true} helperText={false} fullWidth />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput source="last_name" label="Last Name" sx={{ mb: 1 }} required={true} helperText={false} fullWidth />
          </Grid>
        </Grid>
        <Grid container columnSpacing={2} rowSpacing={0}>
          <Grid item xs={12} md={6}>
            <TextInput source="email" label="Email Address" sx={{ mb: 1 }} required={true} helperText={false} fullWidth />
          </Grid>
          <Grid item xs={12} md={6}>
            <PhoneNumberInput source="phone_number" />
          </Grid>
        </Grid>
        <TextInput source="invited_by_id" label={false} helperText={false} defaultValue={identity?.id} fullWidth disabled sx={{ display: 'none' }} />
        <GeocodeInput source="addresses[0]" />
      </SimpleForm>
    </AdminCreate>
  );
};

export default Create;
