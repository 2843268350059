import React from "react";
import {
  Show as AdminShow,
  useRecordContext,
  useShowContext,
  TabbedShowLayout,
  Tab,
  ShowViewProps
} from "react-admin";
import { Chip } from "@mui/material";
import { AcUnit, LocalFireDepartment } from '@mui/icons-material';
import WorkIcon from '@mui/icons-material/Work';
import PaidIcon from '@mui/icons-material/Paid';
import StarsIcon from '@mui/icons-material/Stars';
import HardwareIcon from '@mui/icons-material/Hardware';
import Aside from "src/components/resources/orders/Aside";
import Actions from "src/components/resources/orders/Actions";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import UpdateIcon from '@mui/icons-material/Update';
import RepairTabContent from "src/components/resources/orders/RepairTabContent";
import ShippingTabContent from "src/components/resources/orders/ShippingTabContent";
import PaymentsTabContent from "src/components/resources/orders/PaymentsTabContent";

export const Show = (props: ShowViewProps) => {
  const Title = () => {
    const record = useRecordContext();
    const { isLoading } = useShowContext();
    if (isLoading) return null;
    const isVip = record.user.is_vip
    const isCorporate = !!record.user.corporate_account_id
    const isRush = !!record.rush_fee
    const isFuturePrice = !!record.future_price
    const isPastDue = record.status === 'approved' && record.approved_at <= new Date(Date.now() - 12096e5).toISOString()
    return (
      <>
        <span>
          Repair Order #<strong>{record.confirmation_code}</strong>
        </span>
        <span style={{ verticalAlign: 'text-bottom' }}>
          {isVip && <>{' '}<Chip label="VIP Customer" size="small" color="error" variant="outlined" icon={<StarsIcon />} /></>}
          {isCorporate && <>{' '}<Chip label="Corporate Account" size="small" color="primary" variant="outlined" icon={<WorkIcon />} /></>}
          {isRush && <>{' '}<Chip label="Rush" size="small" color="error" variant="outlined" icon={<LocalFireDepartment />} /></>}
          {isFuturePrice && <>{' '}<Chip label="Future Price" size="small" color="error" variant="outlined" icon={<UpdateIcon />} /></>}
          {isPastDue && <>{' '}<Chip label="Past Due" size="small" color="primary" variant="outlined" icon={<AcUnit />} /></>}
        </span>
      </>
    )
  }
  return (
    <AdminShow title={<Title />} aside={<Aside />} actions={<Actions />} {...props}>
      <TabbedShowLayout>
        <Tab label="Repair" iconPosition="start" icon={<HardwareIcon fontSize="small" />}>
          <RepairTabContent />
        </Tab>
        <Tab label="Shipping &amp; handling" iconPosition="start" icon={<LocalShippingIcon fontSize="small" />}>
          <ShippingTabContent />
        </Tab>
        <Tab label="Payment history" iconPosition="start" icon={<PaidIcon fontSize="small" />}>
          <PaymentsTabContent />
        </Tab>
      </TabbedShowLayout>
    </AdminShow>
  );
};

export default Show;
