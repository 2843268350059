import * as React from 'react';
import { Controller, Control } from 'react-hook-form';
import TextField from '@mui/material/TextField';

export default function QualityControlMemoInput ({ control }: {
  control: Control<{ reason: any; quality_control_memo: any; performed_by_id: any; }>
}) {
  return (
    <Controller
      name="quality_control_memo"
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          label="Quality Control Memo"
          placeholder="Notes for quality control."
          multiline
          rows={3}
          fullWidth
          margin="normal"
          onChange={onChange}
          value={value}
          error={!!error}
          helperText={error ? error.message : null}
        />
      )}
    />
  )
}
