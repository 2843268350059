// For CI build-specific environment variables, see .env files.
// These environment variables are available client-side.

type EnvironmentType = {
  readonly API_URL: string;
  readonly TOKEN_KEY: string;
};

type EnvironmentDictionary = {
  readonly development: EnvironmentType;
  readonly staging: EnvironmentType;
  readonly production: EnvironmentType;
};

const config: EnvironmentDictionary = {
  development: {
    API_URL: "http://localhost:3000",
    TOKEN_KEY: "ls_jwt",
  },
  staging: {
    API_URL: "https://staging-api.leatherspa.com",
    TOKEN_KEY: "ls_jwt",
  },
  production: {
    API_URL: "https://api.leatherspa.com",
    TOKEN_KEY: "ls_jwt",
  },
};

const environment =
  process.env.REACT_APP_ENV || (process.env.NODE_ENV as string);

export default (config as any)[environment];
