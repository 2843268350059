import * as React from 'react';
import { SelectInput, SelectInputProps } from 'react-admin';
import { useWatch } from 'react-hook-form';

export const categoriesByDepartment: {
  [x: string]: { [x: string]: string }
} = {
  dye: {
    dyeing: 'Dyeing'
  },
  embossing: {
    natural_heat_set: 'Natural Heat Set',
    foil: 'Foil'
  },
  stretching: {
    stretching: 'Stretching'
  },
  heel_repair: {
    new_heels: 'New Heels',
    fix_heels: 'Fix Heels',
    recover_heels: 'Recover Heels'
  },
  cleaning: {
    clean: 'Clean',
    dye: 'Dye',
    water_stain_repellant: 'Water & Stain Repellant',
    shine: 'Shine'
  },
  shoe_repair: {
    full_soles: 'Full Soles',
    half_soles: 'Half Soles',
    heels: 'Heels',
    other: 'Others',
    rush: 'RUSH',
    sole_guard: 'Sole Guard',
    taps: 'Taps'
  },
  alterations: {
    clb_one_heel: 'CLB ONE Heel',
    clb_heel_pair: 'CLB Heel PAIR',
    elastic: 'Elastic',
    glue: 'Glue',
    handle: 'Handle',
    hardware: 'Hardware',
    heel_fix: 'Heel Fix',
    insoles: 'Insoles',
    lining: 'Lining',
    miscellaneous: 'Misc.',
    pad: 'Pad',
    patch: 'Patch',
    sling: 'Sling',
    stitch: 'Stitch',
    strap: 'Strap',
    calf_alterations: 'Calf Alterations',
    tassel: 'Tassel',
    velcro: 'Velcro',
    zipper: 'Zipper'
  },
  custom: {
    custom: 'Custom'
  }
};

export const getDepartmentAndCategoryLabel = (department: string, category: string) => {
  const categoryLabel = categoriesByDepartment[department][category];
  // @ts-ignore
  return `${department.titleize()} / ${categoryLabel}`;
}

type DepartmentInputProps = Omit<SelectInputProps, 'source' | 'choices'>;

export const DepartmentInput = (props: DepartmentInputProps) => {
  const choices = Object.keys(categoriesByDepartment).map((department: string) => ({
    // @ts-ignore
    id: department, name: department.titleize()
  }))
  return (
    <SelectInput
      source="department"
      choices={choices}
      {...props}
    />
  )
}

type CategoryInputProps = Omit<SelectInputProps, 'source' | 'choices'>;

export const CategoryInput = (props: CategoryInputProps) => {
  const department: string = useWatch({ name: 'department' });
  if (!department) return <></>
  const categories = department ? Object.entries(categoriesByDepartment[department]) : []
  const choices = categories.map((category) => ({ id: category[0], name: category[1] }))
  return (
    <SelectInput
      source="category"
      choices={choices}
      {...props}
    />
  )
}
