import React from 'react';
import { Button } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';

type PrintButtonProps = {
  label: string;
  url: string;
  disabled?: boolean;
  className?: string;
  style?: any;
}

export default function PrintButton({ label, url, ...props }: PrintButtonProps) {
  return (
    <Button {...props} size="small" color="primary" variant="outlined" href={url} target="_blank">
      <>
        <PrintIcon fontSize="small" sx={{ px: '0 !important' }} />
        &nbsp;{label}
        </>
    </Button>
  )
}
