import React, { useCallback } from 'react'
import {
  ListBase,
  ListProps,
  ListToolbar,
  TextInput,
  required,
  NumberInput,
  useListContext,
  useGetList,
  Pagination,
  ListActionsProps,
  Filter,
  SearchInput,
  BooleanInput,
  useUpdate,
  useNotify,
} from 'react-admin';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import formatCurrency from 'src/utilities/formatCurrency';
import EditDialog from 'src/components/EditDialog';
import CreateDialog from 'src/components/CreateDialog';
import { DepartmentInput, CategoryInput, getDepartmentAndCategoryLabel } from 'src/components/resources/services/DepartmentInputs';
import Checkbox from '@mui/material/Checkbox';

const ListActions = (_props: ListActionsProps) => {
  return (
    <div style={{ marginBottom: '1em' }}>
      <CreateDialog resource="services" label="Create service" description="Create a new repair service." buttonVariant="outlined">
        <TextInput source="name" validate={required()} helperText={false} fullWidth />
        <Grid container columnSpacing={1}>
          <Grid item xs={6}>
            <DepartmentInput helperText={false} fullWidth resettable validate={required()} />
          </Grid>
          <Grid item xs={6}>
            <CategoryInput helperText={false} fullWidth resettable validate={required()} />
          </Grid>
        </Grid>
        <TextInput source="description" helperText={false} fullWidth />
        <NumberInput source="default_price" validate={required()} step="1" min="0" helperText={false} fullWidth />
      </CreateDialog>
    </div>
  )
}

const RepairableCells = ({ repairables }: { repairables: any[] }) => (
  <>
    {repairables.map((repairable: any) => (
      <TableCell key={repairable.id} sx={{ whiteSpace: 'nowrap' }}>
        {repairable.name}
      </TableCell>
    ))}
  </>
)

const ApparelServiceCell = (props: { repairable: any, services: any[], rates: any[] }) => {
  const rate = props.rates.find((rate: any) => rate.repairable_id === props.repairable.id)
  const service = props.services.find((service: any) => service.id === rate.service_id)
  const isPriceOverriden = rate && rate.price_override !== null
  return (
    <TableCell key={props.repairable.id} sx={{ whiteSpace: 'nowrap' }}>
      {rate.is_enabled ? isPriceOverriden ?
        <strong>{formatCurrency(rate.price_override)}</strong> :
        <span style={{ color: 'gray' }}>{formatCurrency(service.default_price)}</span>
        : <span style={{ color: 'gray' }}>Disabled</span>}
      &nbsp;
      <EditDialog
        resource="rates"
        title="Update Rate"
        record={rate}
        description="Update distinct pricing for this service as performed for this specific type of item"
        buttonSize="small"
        buttonVariant="text"
        label={<EditIcon fontSize="inherit" />}
      >
        <NumberInput source="price_override" step="1" min="0" helperText={false} fullWidth />
        <BooleanInput source="is_enabled" helperText={false} fullWidth />
      </EditDialog>
    </TableCell>
  )
}

const ServiceGrid = () => {
  const { data: serviceRows = [] } = useListContext()
  const { data: repairableColumns = [] } = useGetList('repairables', {
    pagination: {
      page: 1, perPage: 100
    }
  })
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Service</TableCell>
            <RepairableCells repairables={repairableColumns} />
          </TableRow>
        </TableHead>
        <TableBody>
          {serviceRows.map((service: any) => (
            <TableRow key={service.id}>
              <TableCell>
                <Grid container flexDirection="row" flexWrap="nowrap" alignItems="center" columnGap={1}>
                  <Grid item>
                    <strong style={{ whiteSpace: 'nowrap' }}>
                      {service.name}
                    </strong>
                    <div>
                      <small>{getDepartmentAndCategoryLabel(service.department, service.category)}</small>
                    </div>
                    <div>
                      <small>Default: {formatCurrency(service.default_price)}</small>
                    </div>
                  </Grid>
                  <Grid>
                    <EditDialog
                      resource="services"
                      title="Update Service"
                      record={service}
                      description="Update base details for this service"
                      buttonSize="small"
                      buttonVariant="text"
                      label={<EditIcon fontSize="small" />}
                      canDelete
                    >
                      <TextInput source="name" validate={required()} helperText={false} fullWidth />
                      <Grid container columnSpacing={1}>
                        <Grid item xs={6}>
                          <DepartmentInput helperText={false} fullWidth resettable validate={required()} />
                        </Grid>
                        <Grid item xs={6}>
                          <CategoryInput helperText={false} fullWidth resettable validate={required()} />
                        </Grid>
                      </Grid>
                      <NumberInput source="default_price" validate={required()} step="1" min="0" helperText={false} fullWidth />
                      <TextInput source="description" helperText={false} fullWidth />
                      <ApparelServiceEnabledInput service={service} />
                    </EditDialog>
                  </Grid>
                </Grid>
              </TableCell>
              {repairableColumns.map((repairable: any) => <ApparelServiceCell repairable={repairable} services={serviceRows} rates={service.rates} />)}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const ApparelServiceEnabledInput = (props: { service: any }) => {
  const { data: rates = [] } = useGetList('rates', {
    filter: {
      service_id: props.service.id
    },
    pagination: {
      page: 1, perPage: 100
    }
  })
  const notify = useNotify()
  const [update] = useUpdate()

  const onClick = useCallback(async (event: any, rate: any) => {
    try {
      await update('rates', {
        id: rate.id,
        data: { is_enabled: event.target.checked },
        previousData: { is_enabled: !event.target.checked }
      })
      notify(`Successfully enabled ${props.service.name} service for ${rate.repairable.name} item`, { type: 'success' })
    } catch (error) {
      console.error(error)
      notify('Server error. Contact the developer', { type: 'error' })
    }
  }, [])

  return (
    <Grid container columnSpacing={1}>
      {rates.map((rate: any) => (
        <Grid item xs={4}>
          <label style={{ cursor: 'pointer' }}>
            <Checkbox size="small" onClick={(e) => onClick(e, rate)} checked={rate.is_enabled} />
            &nbsp;
            <small>{rate.repairable.name}</small>
          </label>
        </Grid>
      ))}
    </Grid>
  )
}

const List = (props: ListProps) => (
  <ListBase resource="services" {...props}>
    <Container disableGutters maxWidth="xl" sx={{ mx: 2, mb: 2 }}>
      <ListToolbar actions={<ListActions />} filters={(
        <Filter {...props}>
          <SearchInput source="q" alwaysOn />
          <DepartmentInput alwaysOn />
          <CategoryInput alwaysOn />
        </Filter>
      )} />
      <ServiceGrid />
    </Container>
    <Pagination rowsPerPageOptions={[10, 25]} />
  </ListBase>
)

export default List
