import * as React from 'react';
import { useDelete, useNotify, useRefresh, ResourceContextProvider, RecordContextProvider } from 'react-admin';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

type FormDialogProps = {
  resource: string;
  record: any;
  label: JSX.Element | string;
  buttonVariant?: "text" | "contained" | "outlined" | undefined;
  description: string;
}

export default function DeleteDialog(props: FormDialogProps) {
  const [isOpen, toggleOpen] = React.useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const [deleteOne] = useDelete(
    props.resource,
    { id: props.record.id, previousData: props.record },
    {
      mutationMode: 'pessimistic',
      onSuccess: () => {
        refresh();
        notify('Successfully deleted record');
      },
      onError: (error: any) => {
        try {
          const errArray = Object.entries(error.body.errors)[0]
          // @ts-ignore
          const message = errArray[0].titleize() + ' ' + errArray[1][0]
          notify(`Error: ${message}.`, { type: 'error' })
        } catch (e) {
          notify('Error: Something went wrong. Contact a developer.', { type: 'error' })
        }
      },
    }
  );
  const onSubmit = async () => {
    deleteOne();
    toggleOpen(false);
  }
  return (
    <ResourceContextProvider value={props.resource}>
      <RecordContextProvider value={props.record}>
        <Button size="small" variant={props.buttonVariant} color="error" onClick={() => toggleOpen(!isOpen)}>
          Delete
        </Button>
        <Dialog open={isOpen} onClose={() => toggleOpen(false)}>
          <DialogTitle>{props.label}</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ mb: 2 }}>
              {props.description}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => toggleOpen(false)}>Cancel</Button>
            <Button onClick={onSubmit}>Confirm</Button>
          </DialogActions>
        </Dialog>
      </RecordContextProvider>
    </ResourceContextProvider>
  );
}

DeleteDialog.defaultProps = {
  buttonVariant: 'text'
}
