import * as React from "react";
import { NumberInput, FormDataConsumer, NumberInputProps } from "react-admin";

const ShippingFeeInput = (props: NumberInputProps) => (
  <FormDataConsumer subscription={{ values: true }}>
    {({ formData: { status } }) =>
      status !== "pending_receipt" ? (
        <NumberInput min="0" step="0.01" {...props} />
      ) : null
    }
  </FormDataConsumer>
);

export default ShippingFeeInput;
