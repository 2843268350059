
import React, { JSXElementConstructor } from 'react'

type StepperProps = {
  step: number
  steps: JSXElementConstructor<StepProps>[]
  setStep: React.Dispatch<number>
}

export type StepProps = {
  next(): void
  previous(): void
}

export type Step = React.ReactElement<StepProps>

export default function Stepper(props: StepperProps): JSX.Element {
  const CurrentStep = props.steps[props.step]
  const totalSteps = props.steps.length

  function next() {
    if (props.step === totalSteps) return
    props.setStep(props.step + 1)
  }

  function previous() {
    if (props.step === 0) return
    props.setStep(props.step - 1)
  }

  return <CurrentStep next={next} previous={previous} />
}
