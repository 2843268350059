import * as React from 'react';
import {
  RichTextInput as RaRichTextInput,
  RichTextInputProps,
  RichTextInputToolbar,
  FormatButtons,
  ListButtons,
  LinkButtons,
  QuoteButtons,
  ClearButtons,
} from 'ra-input-rich-text';

const RichTextInput = (props: RichTextInputProps) => {
  return (
    <RaRichTextInput
      fullWidth
      toolbar={
        <RichTextInputToolbar>
          <FormatButtons size="medium" />
          <ListButtons size="medium" />
          <LinkButtons size="medium" />
          <QuoteButtons size="medium" />
          <ClearButtons size="medium" />
        </RichTextInputToolbar>
      }
      {...props}
    />
  )
};

export default RichTextInput;
