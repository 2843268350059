import * as React from 'react';
import { AppBar as ReactAdminAppBar, AppBarProps, Toolbar } from 'react-admin';
import Typography from '@mui/material/Typography';
import { AppBarWrapper, Logo, Brand } from './style';

const AppBar = (props: AppBarProps) => (
  <AppBarWrapper>
    <ReactAdminAppBar {...props} color="primary" sx={{ zIndex: 0, transform: 'none !important', visibility: 'visible !important' }} position="fixed">
      <Logo src="https://leatherspa.imgix.net/logo.svg" />
      <Brand>LEATHER SPA</Brand>
    </ReactAdminAppBar>
    <Toolbar variant="dense" sx={{ zIndex: 1, width: '100%' }}>
      <Typography
        variant="h6"
        color="inherit"
        id="react-admin-title"
      />
    </Toolbar>
  </AppBarWrapper>
);

export default AppBar;
