import { defaultTheme } from "react-admin";
import { ThemeOptions } from "@mui/styles";
import { CSSObject } from "@emotion/react";

type CssPair = [cssClass: string, css: CSSObject];

type StyleOverride = {
  styleOverrides: {
    root: {
      [x: string]: object;
    };
  };
};

const overrideComponentStyle = (cssPairs: CssPair[]): StyleOverride => ({
  styleOverrides: {
    root: {
      ...Object.fromEntries(cssPairs),
    },
  },
});

const theme: ThemeOptions = {
  ...defaultTheme,
  palette: {
    primary: {
      main: "#301D0E",
      light: "rgb(89, 74, 62)",
      dark: "rgb(33, 20, 9)",
      contrastText: "#fff",
    },
    secondary: {
      main: "#FA8200",
      light: "rgb(251, 155, 51)",
      dark: "rgb(175, 91, 0)",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
  },
  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(","),
  },
  components: {
    ...defaultTheme.components,
    MuiChip: {
      defaultProps: {
        variant: "outlined",
        color: "secondary",
      },
      styleOverrides: {
        root: ({ ownerState }: any) => {
          const styles: CSSObject = {
            borderRadius: 2,
          };
          if (ownerState.color === "secondary") {
            styles.color = "#FA8200";
            styles.border = "1px solid #FA8200 !important";
          }
          if (ownerState.variant === "filled") {
            styles.backgroundColor = "#f7921e20";
          }
          return styles;
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          whiteSpace: "nowrap !important",
        },
      },
    },
    MuiDialog: overrideComponentStyle([
      [
        "& .MuiPaper-root",
        {
          maxWidth: 800,
        },
      ],
    ]),
    MuiListItemText: overrideComponentStyle([
      [
        "& .MuiTypography-root",
        {
          fontSize: "0.75em",
        },
      ],
    ]),
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: 48,
        },
      },
    },
    RaFileInput: overrideComponentStyle([
      [
        "& .RaFileInput-dropZone",
        {
          backgroundColor: "#ebe9e4 !important",
          borderRadius: "0 !important",
        },
      ],
    ]),
    RaFilterForm: {
      styleOverrides: {
        root: {
          flex: "0 1 auto !important",
        },
      },
    },
    RaLayout: overrideComponentStyle([
      [
        "& .RaLayout-appFrame",
        {
          marginTop: 48,
          "& > div": {
            zIndex: 10,
            width: "100%",
          },
        },
      ],
      [
        "& .RaLayout-content",
        {
          marginTop: 48,
        },
      ],
    ]),
    RaSearchInput: overrideComponentStyle([
      [
        "& .MuiInputBase-input",
        {
          paddingTop: "8px !important",
          fontSize: "1rem !important",
        },
      ],
    ]),
    RaFilterFormInput: overrideComponentStyle([
      [
        "& .MuiInputLabel-root",
        {
          lineHeight: "1em",
          fontSize: "0.75rem !important",
        },
      ],
      [
        "& .MuiInputLabel-root[data-shrink=true]",
        {
          fontSize: "0.75rem !important",
        },
      ],
      [
        "& .MuiInputLabel-root[data-shrink=false]",
        {
          fontSize: "0.875rem !important",
        },
      ],
      [
        "& .MuiSelect-select",
        {
          paddingTop: "13px !important",
          fontSize: "0.75rem !important",
        },
      ],
      [
        "& input.MuiInputBase-input",
        {
          paddingTop: 19,
          fontSize: "0.75rem",
        },
      ],
      [
        "& .RaFilterFormInput-spacer",
        {
          width: 6,
        },
      ],
    ]),
    RaSidebar: overrideComponentStyle([
      [
        "& .RaSidebar-fixed",
        {
          marginTop: "48px !important",
        }
      ],
      [
        "&.RaSidebar-appBarCollapsed",
        {
          marginTop: "0 !important",
        }
      ]
    ]),
  },
};

export default theme;
