import * as React from 'react';
import { FieldProps, FunctionField, RaRecord } from 'react-admin';
import { Done, Clear } from '@mui/icons-material';

const CuteBooleanField = (props: FieldProps) => {
  if (!props.source) return null;
  /* @ts-ignore */
  return <FunctionField {...props} render={(record: RaRecord) => !!record[props.source] ? <Done color="error" /> : <Clear color="disabled" />} />
}

export default CuteBooleanField;
