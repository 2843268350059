import * as React from 'react';
import { useRecordContext, ListBase, Datagrid, TextField, FunctionField, RaRecord, DateField } from 'react-admin';
import { Button } from '@mui/material';
import formatCurrency from 'src/utilities/formatCurrency';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PrintIcon from '@mui/icons-material/Print';
import SyncButton from 'src/components/resources/payments/SyncButton'
import env from 'src/env';

const PaymentsTabContent = () => {
  const record = useRecordContext();
  return (
    <ListBase
      resource="payments"
      filter={{ order_id: record.id }}
      sort={{ field: 'created_at', order: 'DESC' }}
      perPage={25}
    >
      <Datagrid bulkActionButtons={false} size="small">
        <FunctionField label="Note" source="formatted_notes" render={(record: RaRecord) => record.formatted_notes.join(', ')} sortable={false} />
        <FunctionField label="Payment method" render={(payment: RaRecord) => {
          if (payment.payment_method === 'credit_card') {
            return `${(payment.card_brand === 'AMERICAN_EXPRESS' ? 'AMEX' : payment.card_brand)} x${payment.card_last4}`
          } else if (payment.payment_method === 'cash') {
            return "CASH"
          }
          }} />
        <FunctionField label="Amount" source="amount_cents" textAlign="right" render={(record: RaRecord) => formatCurrency(record.amount_cents / 100)} sortable={false} />
        <TextField source="status" sortable={false} />
        <DateField label="Last updated" source="updated_at" sortable={false} />
        <FunctionField label={false} render={() => <SyncButton />} />
        <FunctionField label={false} render={(record: RaRecord) => <Button
            size="small"
            color="primary"
            variant="text"
            title="Open Transaction in Square Dashboard"
            target="_blank" href={`${env.API_URL}/rest/payments/${record.id}.html`}
          >
            <PrintIcon fontSize="small" />
          </Button>
        } />
        <FunctionField label={false} render={(record: RaRecord) => <Button
            size="small"
            color="primary"
            variant="text"
            title="Open Transaction in Square Dashboard"
            href={`https://squareup.com/dashboard/sales/transactions/${record.square_order_id}`}
          >
            <OpenInNewIcon fontSize="small" />
          </Button>
        } />
      </Datagrid>
    </ListBase>
  );
};

export default PaymentsTabContent;
