import * as React from 'react';
import { Button } from '@mui/material';

type QuickFilterButtonProps = {
  title: string;
  icon: JSX.Element;
  setActiveKey: Function;
  activeKey?: string;
  filterKey: string;
}

const QuickFilterButton = (props: QuickFilterButtonProps) => {
  const isActive = props.filterKey === props.activeKey
  const onClick = () => isActive ? props.setActiveKey(undefined) : props.setActiveKey(props.filterKey)
  return (
    <Button
      size="small"
      color="primary"
      sx={{ marginBottom: '1em', whiteSpace: 'nowrap' }}
      variant={isActive ? "contained" : "outlined"}
      onClick={onClick}
      startIcon={props.icon}
    >
      {props.title}
    </Button>
  );
};

export default QuickFilterButton;
