
import './App.module.css'
import React from 'react';
import { Route } from 'react-router-dom';
import { Admin, CustomRoutes, Resource, memoryStore } from "react-admin";
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import PersonIcon from '@mui/icons-material/Person';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

import theme from "./themes/theme";
import Layout from "./components/Layout";

import authProvider from "./providers/auth";
import dataProvider from "./providers/data";

import LoginPage from "./components/Login";

import * as Category from "./resources/category";
import * as Order from "./resources/order";
import * as OrderItem from "./resources/order_item";
import * as Service from "./resources/service";
import * as User from "./resources/user";
import SortingPage from './components/pages/sorting';
import RackingPage from './components/pages/racking';
import WorkInPage from './components/pages/workIn';
import WorkOutPage from './components/pages/workOut';

// @ts-ignore
// eslint-disable-next-line no-extend-native
String.prototype.titleize = function (): string {
  return this.replaceAll("_", " ").replace(/(?:\s|^)('?[a-zà-ÿ])/g, function (m) {
    return m.toUpperCase();
  });
};

export default function App() {
  return (
    <Admin
      title="Leather Spa Dashboard"
      layout={Layout}
      theme={theme}
      authProvider={authProvider}
      dataProvider={dataProvider}
      loginPage={LoginPage}
      store={memoryStore()}
      requireAuth
    >
      {permissions => (
        <>
          <CustomRoutes>
            <Route path="/racking" element={<RackingPage />} />
            <Route path="/sorting" element={<SortingPage />} />
            <Route path="/work-in" element={<WorkInPage />} />
            <Route path="/work-out" element={<WorkOutPage />} />
          </CustomRoutes>
          <Resource
            name="orders"
            icon={ConfirmationNumberIcon}
            options={{ label: "Repair Orders" }}
            edit={Order.Edit}
            list={Order.List}
            show={Order.Show}
          />
          <Resource
            name="users"
            icon={PersonIcon}
            options={{ label: "Customers" }}
            list={User.List}
            show={User.Show}
            create={User.Create}
          />
          <Resource name="addresses" />
          <Resource name="bins" />
          <Resource name="categories" edit={Category.Edit} />
          <Resource name="estimates" />
          <Resource name="inquiries" />
          <Resource name="services" icon={PriceChangeIcon} options={{ label: "Repair Services" }} list={Service.List} />
          <Resource name="order_items" icon={ManageSearchIcon} options={{ label: "Quality Control" }} list={OrderItem.List} edit={OrderItem.Edit} />
          <Resource name="promotions" />
          <Resource name="quotes" />
          <Resource name="rates" />
          <Resource name="repairables" />
          <Resource name="storefronts" />
        </>
      )}
    </Admin>
  );
}
