import { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import Card from '@mui/material/Card';
import { Box } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const BinTable = ({ bins }: { bins: any[] }) => (
  <TableContainer component={Paper} sx={{ maxHeight: '100%' }}>
    <Table stickyHeader size="small">
      <TableHead>
        <TableRow>
          <TableCell>Bin</TableCell>
          <TableCell>Storefront</TableCell>
          <TableCell>Order</TableCell>
          <TableCell>Items</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {bins.map((bin, index) => (
          <TableRow key={index}>
            <TableCell>{bin.number}</TableCell>
            <TableCell>{bin.order && bin.order.storefront.code}</TableCell>
            <TableCell>{bin.order && bin.order.confirmation_code}</TableCell>
            <TableCell>{bin.order && bin.items.length}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default function BinList() {
  const [bins, setBins] = useState<any[]>([]);
  const dataProvider = useDataProvider();
  useEffect(() => {
    const fetchBins = async () => {
      let response
      try {
        response = await dataProvider.getList('bins', {
          pagination: { page: 1, perPage: 200 },
          sort: { field: 'created_at', order: 'DESC' },
          filter: {}
        });
        setBins(response.data);
      } catch {
        setBins([]);
      }
    }
    fetchBins();
  }, [])

  return (
    <Card sx={{ height: '100%', overflowY: 'hidden' }}>
      <Box sx={{ width: '100%', height: '100%' }}>
        <BinTable bins={bins} />
      </Box>
    </Card>
  );
}
