import * as React from 'react';
import { FieldProps, FunctionField, RaRecord } from 'react-admin';
import StarsIcon from '@mui/icons-material/Stars';
import LocalFireDepartment from '@mui/icons-material/LocalFireDepartment';
import UpdateIcon from '@mui/icons-material/Update';
import { Chip } from '@mui/material';

const OrderCodeField = (props: FieldProps) => {
  return <FunctionField {...props} render={(record: RaRecord) => {
    const isVip = record.user.is_vip
    const isRush = !!record.rush_fee
    const futurePrice = !!record.future_price
    return (
      <>
        <strong>{record.confirmation_code}</strong>
        <span style={{ float: 'right' }}>
          {isVip && <>&nbsp;&nbsp;<Chip label="VIP" size="small" color="error" variant="outlined" icon={<StarsIcon />} /></>}
          {isRush && <>&nbsp;&nbsp;<Chip label="Rush" size="small" color="error" variant="outlined" icon={<LocalFireDepartment />} /></>}
          {futurePrice && <>&nbsp;&nbsp;<Chip label="Rush" size="small" color="error" variant="outlined" icon={<UpdateIcon />} /></>}
        </span>
      </>
    )
  }} />
}

export default OrderCodeField;
