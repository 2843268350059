import * as React from 'react';
import {
  Typography,
  Step,
  StepLabel,
  StepContent,
} from '@mui/material';
import BeenhereIcon from '@mui/icons-material/Beenhere';

type CompletedStepProps = {
  timestamp: string;
  label: string;
  last?: boolean;
  className?: string;
}

const CompletedStep = (props: CompletedStepProps) => {
  if (!props.timestamp) {
    return <></>;
  }
  return (
    <Step expanded completed last={props.last}>
      <StepLabel icon={<BeenhereIcon fontSize="small" color="disabled" />}>
        {new Date(props.timestamp).toLocaleString('en-US', {
          weekday: 'long',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })}
      </StepLabel>
      <StepContent>
        <Typography variant="subtitle1" color="textSecondary">
          {props.label}
        </Typography>
      </StepContent>
    </Step>
  )
};

export default CompletedStep;
