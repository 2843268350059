import React, { useCallback, useEffect, useState } from 'react'
import {
  List as AdminList,
  Datagrid,
  Filter,
  TextField,
  SearchInput,
  SelectInput,
  ListProps,
  DateField,
  DateInput,
  BooleanInputProps,
  useListContext,
  ListActionsProps,
  NumberInput,
  required,
  TextInput,
  BooleanInput,
  useGetList,
} from 'react-admin'
import { useWatch } from 'react-hook-form';
import { Button, Divider, Grid } from '@mui/material'
import LocalFireDepartment from '@mui/icons-material/LocalFireDepartment'
import UpdateIcon from '@mui/icons-material/Update';
import ReceiveMethodField from 'src/components/resources/orders/ReceiveMethodField'
import ReturnMethodField from 'src/components/resources/orders/ReturnMethodField'
import StatusField from 'src/components/resources/shared/StatusField'
import OrderCodeField from 'src/components/resources/orders/OrderCodeField'
import CreateDialog from 'src/components/CreateDialog';
import dataProvider from 'src/providers/data';

const statuses = [
  'pending_receipt',
  'received',
  'invoiced',
  'approved',
  'repaired',
  'pending_return',
  'returned'
]

const receiveMethods = [{
  id: 'store_dropoff',
  name: 'Store Dropoff'
}, {
  id: 'diy_shipping',
  name: 'DIY Mail-In'
}, {
  id: 'shipping_label',
  name: 'UPS Shipping Label'
}, {
  id: 'ups_dropoff',
  name: 'UPS Store Dropoff'
}, {
  id: 'standard_courier',
  name: 'Standard Courier'
}, {
  id: 'ondemand_courier',
  name: 'On-Demand Courier'
}, {
  id: 'locker_dropoff',
  name: 'Golocker Dropoff'
}]

const returnMethods = [{
  id: 'store_pickup',
  name: 'Store Pickup'
}, {
  id: 'ups_shipping',
  name: 'UPS Shipping'
}, {
  id: 'standard_courier',
  name: 'Courier Delivery'
}]

type ButtonInputProps = {
  icon: any
} & Omit<BooleanInputProps, 'options'>

const ButtonInput = (props: ButtonInputProps) => {
  const { setFilters, filterValues } = useListContext()
  const [isActive, toggleActive] = useState(false)
  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    toggleActive(!isActive)
  }, [isActive])

  useEffect(() => {
    const filters = filterValues
    if (isActive) filters[props.source] = true
    else delete filters[props.source]
    setFilters(filters, {})
  }, [isActive, props.source])

  return (
    <Button
      size="small"
      color="primary"
      sx={{ py: 1, mb: 0.375 }}
      variant={isActive ? "contained" : "outlined"}
      startIcon={props.icon}
      onClick={handleClick}
    >
      {/* @ts-ignore */}
      {props.source.titleize()}
    </Button>
  )
}

const ReturnAddressInput = (): JSX.Element => {
  const { data, isLoading } = useGetList('addresses');
  const [defaultAddressId, setDefaultAddressId] = React.useState<string | undefined>()
  const [returnAddresses, setReturnAddresses] = React.useState<any | undefined[]>([])
  useEffect(() => {
    async function fetch() {
      if (!isLoading) {
        const choices = data?.map((address: any) => ({
          id: address.id,
          name: address.formatted
        }))
        setReturnAddresses(choices)
        if (choices?.length) setDefaultAddressId(choices[0].id)
      }
    }
    fetch()
  }, [isLoading])
  return (
    <SelectInput
      source="return_address_id"
      label="Return address"
      choices={returnAddresses}
      validate={[required()]}
      defaultValue={defaultAddressId}
      sx={{ mb: 1 }}
      helperText={false}
      fullWidth
      alwaysOn
    />
  )
}

const RoundtripMethodInput = (): JSX.Element => {
  const [defaultMethod] = React.useState('store_dropoff')
  const [shippingMethods, setShippingMethods] = React.useState([])
  // get address postal code from return_address_id
  const returnAddressId: string | undefined = useWatch({ name: 'return_address_id' })
  useEffect(() => {
    async function fetch() {
      if (returnAddressId) {
        const { data: address } = await dataProvider.getOne('addresses', { id: returnAddressId })
        if (address?.id) {
          const { data } = await dataProvider.getShippingMethods(address)
          setShippingMethods(data)
        }
      }
    }
    fetch()
  }, [returnAddressId])
  if (!returnAddressId) return <p style={{ color: 'red', marginTop: 0 }}>
    Select a return address to calculate available shipping methods
  </p>
  return (
    <SelectInput
      source="roundtrip_method"
      label="Shipping method"
      choices={shippingMethods}
      validate={[required()]}
      defaultValue={defaultMethod}
      sx={{ mb: 1 }}
      helperText={false}
      fullWidth
      alwaysOn
    />
  )
}

const StorefrontInput = (): JSX.Element => {
  const { data, isLoading } = useGetList('storefronts');
  const [defaultStorefrontId, setStorefrontId] = React.useState<string | undefined>()
  const [storefronts, setStorefronts] = React.useState<any | undefined[]>([])
  useEffect(() => {
    async function fetch() {
      if (!isLoading) {
        const choices = data?.map((store: any) => ({
          id: store.id,
          name: store.name
        }))
        setStorefronts(choices)
        if (choices?.length) setStorefrontId(choices[0].id)
      }
    }
    fetch()
  }, [isLoading])
  return (
    <SelectInput
      source="storefront_id"
      label="Storefront"
      choices={storefronts}
      validate={[required()]}
      defaultValue={defaultStorefrontId}
      fullWidth
      alwaysOn
    />
  )
}

const RushInput = (props: Omit<BooleanInputProps, 'options'>) => {
  const { setFilters, filterValues } = useListContext()
  const [isActive, toggleActive] = useState(false)
  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    toggleActive(!isActive)
  }, [isActive])

  useEffect(() => {
    const filters = filterValues
    if (isActive) filters.is_rush = true
    else delete filters.is_rush
    setFilters(filters, {})
  }, [isActive])

  return (
    <Button
      size="small"
      color="primary"
      sx={{ py: 1, mb: 0.375 }}
      variant={isActive ? "contained" : "outlined"}
      startIcon={<LocalFireDepartment />}
      onClick={handleClick}
    >
      Rush
    </Button>
  )
}

const CreateOrderDialog = () => {
  return (
    <CreateDialog resource="orders" label="Create Repair Order" description="" buttonColor="secondary">
      <Grid container columnSpacing={2} rowSpacing={0}>
        <Grid item xs={12} md={6}>
          <TextInput source="external_id" label="Internal Reference Number" sx={{ mb: 1 }} helperText={false} fullWidth />
        </Grid>
      </Grid>
      <TextInput source="description" label="Order Note" helperText="Any important context worth mentioning." fullWidth multiline />
      <Grid item xs={12} md={6}>
        <BooleanInput label="Rush?" source="is_rush" sx={{ mb: 1 }} helperText={false} fullWidth />
      </Grid>
      <ReturnAddressInput />
      <RoundtripMethodInput />
      <StorefrontInput />
      <Divider sx={{ marginBottom: '1em' }} />
      <Grid container columnSpacing={2} rowSpacing={0}>
        <Grid item xs={12} md={6}>
          <SelectInput
            label="Item type"
            source="items[0].repairable_id"
            choices={[
              { id: "8cee18b2-7042-4fdc-8fb3-f570cc01bf56", name: "Women's Shoes" },
              { id: "872406cc-1c2e-4202-8875-8665bdf0381f", name: "Women's Boots" },
              { id: "2d3ca476-5ced-4f64-8085-91e29bb87dc5", name: "Men's Shoes" },
              { id: "0414fcc1-187c-479d-806a-e31d3788267b", name: "Men's Boots" },
              { id: "cad08b3c-5447-47b8-920a-73c1a6a4b719", name: "Bags" },
              { id: "15d8e99f-2c38-439f-a558-4a064595102a", name: "Sneakers" },
              { id: "bad08676-7af7-4877-aab1-7d52ef25cd9a", name: "Belts" },
              { id: "ffaccb2d-c9c4-44d3-8307-be3743342bb7", name: "Garments" },
              { id: "3c1574ea-1860-499f-affb-34fe4eb54b42", name: "Wallets" },
            ]}
            defaultValue="8cee18b2-7042-4fdc-8fb3-f570cc01bf56"
            sx={{ mb: 1 }}
            helperText={false}
            validate={[required()]}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            label="Brand"
            source="items[0].brand"
            validate={[required()]}
            sx={{ mb: 1 }}
            helperText={false}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            label="Color"
            source="items[0].color"
            validate={[required()]}
            sx={{ mb: 1 }}
            helperText={false}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            label="Material"
            source="items[0].material"
            validate={[required()]}
            sx={{ mb: 1 }}
            helperText={false}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <NumberInput
            label="Estimated Value (USD)"
            source="items[0].estimated_value"
            step={1}
            min={0}
            helperText="Optional"
            fullWidth
          />
        </Grid>
      </Grid>
      <TextInput
        label="Service Request"
        source="items[0].description"
        helperText="Please tell us your repair needs."
        fullWidth
        multiline
      />
    </CreateDialog>
  )
}

const ListActions = (_props: ListActionsProps) => {
  return (
    <div style={{ marginBottom: '1em' }}>
      <CreateOrderDialog />
    </div>
  )
}

const List = (props: ListProps) => {
  return (
    <AdminList
      title="Repair Orders"
      actions={<ListActions />}
      filters={(
        <Filter {...props}>
          <SearchInput source="q" alwaysOn />
          {/* @ts-ignore */}
          <SelectInput source="status" choices={statuses.map((status) => ({ id: status, name: status.titleize() }))} emptyText="Any" alwaysOn />
          <DateInput source="latest_status_at" label="Status since" format={(v: any) => v?.gte} parse={(gte: any) => ({ gte })} alwaysOn />
          <SelectInput source="receive_method" choices={receiveMethods} emptyText="Any" alwaysOn />
          <SelectInput source="return_method" choices={returnMethods} emptyText="Any" alwaysOn />
          <ButtonInput source="is_rush" icon={<LocalFireDepartment />} alwaysOn />
          <ButtonInput source="future_price" icon={<UpdateIcon />} alwaysOn />
        </Filter>
      )}
      sort={{ field: "latest_status_at", order: 'DESC' }}
      {...props}
    >
      <Datagrid bulkActionButtons={false} rowClick="show">
        <OrderCodeField label="Order" />
        <StatusField label="Status" source="status" />
        <DateField label="Status since" source="latest_status_at" />
        <ReceiveMethodField label="Receive Method" displayTime={false} />
        <ReturnMethodField label="Return Method" />
        <TextField source="storefront.name" label="Storefront" sortable={false} />
      </Datagrid>
    </AdminList>
  )
}

export default List
