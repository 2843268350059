import React from "react";
import {
  TextField,
  RichTextField,
  Show as AdminShow,
  useGetList,
  useRecordContext,
  useListContext,
  useShowContext,
  EditProps,
  Labeled,
  ListBase,
  FilterList,
  FilterListItem,
  Datagrid,
  Pagination,
  DateField
} from "react-admin";
import { Container, Typography, Chip, Grid, Divider } from '@mui/material';
import StarsIcon from '@mui/icons-material/Stars';
import IcecreamIcon from '@mui/icons-material/Icecream';
import WorkIcon from '@mui/icons-material/Work';
import Actions from 'src/components/resources/users/Actions';
import OrderCodeField from 'src/components/resources/orders/OrderCodeField';
import StatusField from 'src/components/resources/shared/StatusField';
import ReceiveMethodField from 'src/components/resources/orders/ReceiveMethodField';
import ReturnMethodField from 'src/components/resources/orders/ReturnMethodField';

type StatusTotals = {
  pending_receipt: number
  received: number
  invoiced: number
  approved: number
  repaired: number
  pending_return: number
  returned: number
  canceled: number
};

type Statuses = keyof StatusTotals;

const statuses: Statuses[] = [
  'pending_receipt', 'received', 'invoiced', 'approved', 'repaired', 'pending_return', 'returned', 'canceled'
];

const useGetTotal = (status: Statuses, filterValues: any): number => {
  const record = useRecordContext();
  return useGetList('orders', {
    pagination: { perPage: 1, page: 1 },
    sort: { field: 'created_at', order: 'ASC' },
    filter: { ...filterValues, status, user_id: record.id },
  }).total || 0
}

const useGetTotals = (filterValues: any): StatusTotals => {
  return {
    pending_receipt: useGetTotal('pending_receipt', filterValues),
    received: useGetTotal('received', filterValues),
    invoiced: useGetTotal('invoiced', filterValues),
    approved: useGetTotal('approved', filterValues),
    repaired: useGetTotal('repaired', filterValues),
    pending_return: useGetTotal('pending_return', filterValues),
    returned: useGetTotal('returned', filterValues),
    canceled: useGetTotal('canceled', filterValues)
  };
};

const OrdersList = () => {
  const { filterValues } = useListContext();
  const totals = useGetTotals(filterValues);
  return (
    <Container disableGutters maxWidth="xl" sx={{ mx: 2, mb: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={3} lg={2}>
          <FilterList label="Status" icon={<></>}>
            <FilterListItem label="All" value={{ status: true }} />
            {statuses.map(status => (
              // @ts-ignore
              <FilterListItem key={status} label={totals[status] ? `${status.titleize()} (${totals[status]})` : status.titleize()} value={{ status }} />
            ))}
          </FilterList>
        </Grid>
        <Grid item xs={9} lg={10} mt={1}>
          <Datagrid bulkActionButtons={false} rowClick="show" sx={{ width: '100%' }}>
            <OrderCodeField label="Order" />
            <StatusField label="Status" source="status" />
            <DateField label="Since..." source="latest_status_at" />
            <ReceiveMethodField label="Receive Method" displayTime={false} />
            <ReturnMethodField label="Return Method" />
            <TextField source="storefront.name" label="Storefront" sortable={false} />
          </Datagrid>
          <Pagination />
        </Grid>
      </Grid>
    </Container>
  );
};

const ShowContent = () => {
  const record = useRecordContext();
  const { isLoading } = useShowContext();
  const labelStyle = { '& span': { fontWeight: 900 } }
  if (isLoading) return null;
  return (
    <>
      <Container disableGutters maxWidth="xl" sx={{ m: 2 }}>
        <Typography variant="h6">{record.name}</Typography>
        <Typography variant="caption" marginBottom={1} display="block">
          Registered since {new Date(record.created_at).toDateString()}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={3} lg={2}>
            <Grid container spacing={2}>
              <Grid item>
                <Labeled label="Corporate Account" sx={{ display: 'block', ...labelStyle }}>
                  <Typography variant="subtitle2">
                    {record.corporate_account ? record.corporate_account.name : 'Not a corporate account'}
                  </Typography>
                </Labeled>
                <Labeled label="Email Address" sx={{ display: 'block', ...labelStyle }}>
                  <Typography variant="subtitle2" marginBottom={1}>
                    {record.email}
                  </Typography>
                </Labeled>
                <Labeled label="Phone Number" sx={{ display: 'block', ...labelStyle }}>
                  <Typography variant="subtitle2" marginBottom={1}>
                    {record.phone_number}
                  </Typography>
                </Labeled>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={9} lg={10}>
            <Labeled label="Memo" sx={{ display: 'block', marginBottom: 0.5, ...labelStyle }}>
              <RichTextField source="internal_note" emptyText="No memo." />
            </Labeled>
          </Grid>
        </Grid>
      </Container >
      <Divider />
      <ListBase
        resource="orders"
        storeKey="userOrders"
        filterDefaultValues={{ user_id: record.id, status: statuses[0] }}
        filter={{ user_id: record.id }}
        sort={{ field: 'latest_status_at', order: 'ASC' }}
        perPage={25}
      >
        <OrdersList />
      </ListBase>
    </>
  )
};

const Show = (props: EditProps) => {
  const Title = () => {
    const record = useRecordContext();
    const { isLoading } = useShowContext();
    if (isLoading) return null;
    const isVip = record.is_vip;
    const isCorporate = !!record.corporate_account_id;
    return (
      <>
        <strong>{record.name}</strong>
        <span style={{ verticalAlign: 'text-bottom' }}>
          <>
            {' '}
            {isVip ? (
              <Chip label="VIP Customer" size="small" color="error" variant="outlined" icon={<StarsIcon />} />
            ) : (
              <Chip label="Standard Customer" size="small" color="primary" variant="outlined" icon={<IcecreamIcon />} />
            )}
          </>
          {isCorporate && <>{' '}<Chip label="Corporate Account" size="small" color="primary" variant="outlined" icon={<WorkIcon />} /></>}
        </span>
      </>
    )
  }
  return (
    <AdminShow title={<Title />} {...props} actions={<Actions />}>
      <ShowContent />
    </AdminShow >
  );
};

export default Show;
