import * as React from 'react';
import { FunctionField, RaRecord, FieldProps } from 'react-admin';

const ReturnMethodField = (props: FieldProps) => {
  return (
    <FunctionField
      {...props}
      render={(record: RaRecord) => {
        if (record.return_method === 'ups_shipping') {
          return "UPS Return"
        } else if (record.return_method === 'standard_courier') {
          return "Courier Return"
        } else if (record.return_method === 'store_pickup') {
          return "Store Pickup"
        } else if (record.return_method === 'locker_pickup') {
          return "Golocker Pickup"
        }
      }}
    />
  )
}
export default ReturnMethodField;
