import React, { createContext, useContext, useMemo, useState, ReactNode, useEffect } from 'react'
import dataProvider from './data'

interface RackingContextType {
  code: Code
  display: Display
  ticketQuery: string
  setTicketQuery: (query: string) => void
  storefrontId?: string
  setStorefrontId: (query: string) => void
  orderItem?: any | undefined
  setOrderItem: React.Dispatch<React.SetStateAction<Record<string, unknown> | undefined>>
  searchOrderItem: (debouncedValue: string) => Promise<void>
  rackNumber: string | null
  setRackNumber: React.Dispatch<React.SetStateAction<string | null>>
}

const RackingContext = createContext<RackingContextType | undefined>(undefined)

export type Code = 'not_ready' | 'ready' | 'racked' | 'invalid' | 'not_found' | 'initial'

export type Display = {
  textColor: 'black' | 'white'
  backgroundColor: 'red' | 'green' | 'gray' | 'transparent'
  message: string
}

const statusToCode = {
  'pending_receipt': 'not_ready',
  'received': 'not_ready',
  'invoiced': 'not_ready',
  'approved': 'not_ready',
  'repaired': 'ready',
  'pending_return': 'ready',
  'canceled': 'ready',
  'returned': 'invalid',
}

const getDisplay = (code: Code): Display => {
  switch (code) {
    case 'not_ready':
      return {
        textColor: 'white',
        backgroundColor: 'red',
        message: 'Order not ready. Please check order details.'
      }
    case 'ready':
      return {
        textColor: 'black',
        backgroundColor: 'green',
        message: 'Order ready.'
      }
    case 'invalid':
      return {
        textColor: 'black',
        backgroundColor: 'gray',
        message: 'Racking not applicable for this order.'
      }
    case 'racked':
      return {
        textColor: 'black',
        backgroundColor: 'gray',
        message: 'Already racked.'
      }
    case 'not_found':
      return {
        textColor: 'black',
        backgroundColor: 'gray',
        message: 'Order not found.'
      }
    default:
      return {
        textColor: 'black',
        backgroundColor: 'transparent',
        message: 'Start scanning ticket barcodes'
      }
  }
}

export const RackingScreenProvider = ({ children }: { children: ReactNode }) => {
  const [storefrontId, setStorefrontId] = useState<string>()
  const [ticketQuery, setTicketQuery] = useState('')
  const [rackNumber, setRackNumber] = useState<string | null>(null)
  const [orderItem, setOrderItem] = useState<any>()

  const code: Code = useMemo(() => {
    let code = (statusToCode[orderItem?.order.status as keyof typeof statusToCode]) as Code
    if (orderItem) {
      if (orderItem.order.return_method !== 'store_pickup') code = 'invalid'
      if (orderItem.order.rack_number !== null) code = 'racked'
    } else if (ticketQuery !== '') {
      code = 'not_found'
    } else {
      code = 'initial'
    }
    return code
  }, [orderItem, ticketQuery])

  const display: Display = useMemo(() => getDisplay(code), [code])

  const searchOrderItem = async (debouncedValue: string) => {
    const { data } = await dataProvider.getList('order_items', {
      filter: { q: `"${debouncedValue}"` },
      sort: { field: 'created_at', order: 'DESC' },
      pagination: { page: 1, perPage: 1 },
    })
    setTicketQuery(debouncedValue)
    if (data.length === 0) {
      setOrderItem(undefined)
    } else {
      const orderItem = data.find((item: any) => item.code === debouncedValue)
      if (orderItem) {
        setOrderItem({ ...orderItem })
      } else {
        setOrderItem(undefined)
      }
    }
  }

  useEffect(() => {
    setRackNumber(orderItem?.order.rack_number || null)
  }, [orderItem])

  return (
    <RackingContext.Provider value={{
      code,
      display,
      ticketQuery,
      setTicketQuery,
      storefrontId,
      setStorefrontId,
      orderItem,
      setOrderItem,
      searchOrderItem,
      rackNumber,
      setRackNumber
    }}>
      {children}
    </RackingContext.Provider>
  )
}

export const useRackingContext = () => {
  const context = useContext(RackingContext)
  if (!context) {
    throw new Error('useRackingContext must be used within a RackingScreenProvider')
  }
  return context
}
