import { useEffect, useState, useRef } from 'react';
// import useSound from 'use-sound';
import { SxProps, Theme } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import useDebounce from 'src/hooks/useDebounce';
// import errorSound from 'src/audio/error.mp3';
// import successSound from 'src/audio/success.mp3';
import SortingMessage from './SortingMessage';
import { Code, Display, useSortingContext } from 'src/providers/sortingScreen';

const cardSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  height: '100%',
  marginY: '1em',
  paddingX: '1em'
}

const processResult = (code: Code): Display => {
  switch (code) {
    case 'not_ready':
      return {
        showCount: true,
        textColor: 'white',
        backgroundColor: 'red',
        message: 'Order not ready. Please check order details.'
      }
    case 'item_incomplete':
      return {
        showCount: true,
        textColor: 'white',
        backgroundColor: 'red',
        message: 'Item not completed. Please return to QC.'
      }
    case 'item_complete':
      return {
        showCount: true,
        textColor: 'black',
        backgroundColor: 'blue',
        message: 'Item completed'
      }
    case 'order_complete':
      return {
        showCount: true,
        textColor: 'black',
        backgroundColor: 'green',
        message: 'Order already sorted.'
      }
    case 'outbound':
      return {
        showCount: true,
        textColor: 'white',
        backgroundColor: 'red',
        message: 'Order already outbound'
      }
    case 'canceled':
      return {
        showCount: true,
        textColor: 'black',
        backgroundColor: 'green',
        message: 'ORDER CANCELED'
      }
    case 'not_found':
      return {
        showCount: false,
        textColor: 'white',
        backgroundColor: 'red',
        message: 'Item not found'
      }
    case 'unknown':
      return {
        showCount: false,
        textColor: 'black',
        backgroundColor: 'yellow',
        message: 'Unknown error'
      }
    default:
      return {
        showCount: false,
        textColor: 'black',
        backgroundColor: 'transparent',
        message: 'Start scanning ticket barcodes'
      }
  }
}

export default function SortingCard() {
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState('');
  const { code, query, setQuery, orderItem } = useSortingContext()
  const debouncedValue = useDebounce(inputValue, 500);
  // const [playError] = useSound(errorSound);
  // const [playSuccess] = useSound(successSound);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value);

  const display = processResult(code!)

  useEffect(() => {
    setQuery(debouncedValue)
    setInputValue('');
    if (inputRef.current) inputRef.current.value = '';
  }, [debouncedValue]);

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent sx={{ ...cardSx, backgroundColor: display.backgroundColor, color: `${display.textColor} !important`, marginTop: 0 }}>
        <input
          type="text"
          ref={inputRef}
          onChange={handleChange}
          autoFocus={true}
          onBlur={(e) => {
            e.preventDefault();
            const target = e.currentTarget as HTMLInputElement;
            setTimeout(function () { target.focus() }, 5)
          }}
          style={{ opacity: 0.0, position: 'absolute', left: '-9999px' }}
        />
        {code !== undefined
          ? orderItem !== undefined ? <SortingMessage display={display} /> : (
          <div style={{ textAlign: 'center' }}>
            <h1 style={{ fontSize: '3rem', marginBottom: '0.2em' }}>
              <>Could not find: {query}</>
            </h1>
          </div>
        ) : (
          <h1>
            Start scanning
            <br />
            ticket barcodes
          </h1>
        )}
      </CardContent>
    </Card>
  );
};
