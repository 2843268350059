import * as React from 'react';
import { FieldProps, WrapperField, SelectInput, useRecordContext, useDataProvider } from "react-admin";
import { Typography } from '@mui/material';
import EditDialog from 'src/components/EditDialog';

const RackingNumberField = (props: FieldProps) => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const [choices, setChoices] = React.useState([]);
  React.useEffect(() => {
    async function fetch() {
      const { data } = await dataProvider.getOne(
        "storefronts",
        {
          id: record.storefront_id
        }
      );
      const rackNumbers = data.rack_numbers.map((rackNumber: string) => ({ id: rackNumber, name: rackNumber }));
      setChoices(rackNumbers);
    }
    if (record.storefront_id) {
      fetch()
    }
  }, [record.storefront_id]);
  if (record.storefront_id) {
    return (
      <WrapperField>
        <Typography variant="body2">
          {record.rack_number ? record.rack_number : (
            <EditDialog
              resource="orders"
              record={record}
              buttonColor="error"
              buttonVariant="contained"
              label="Add racking number"
              description="Indicate which rack the order can be found for return delivery or store pickup"
            >
              <SelectInput source="rack_number" choices={choices} fullWidth />
            </EditDialog>
          )}
        </Typography>
      </WrapperField>
    )
  }
  return (
    <WrapperField>
      <Typography variant="body2">
        Requires a store.
      </Typography>
    </WrapperField>
  )
}

export default RackingNumberField;
