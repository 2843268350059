import { styled } from "@mui/material";

export const AppBarWrapper = styled("div")(() => ({
  position: "fixed",
}));

export const Logo = styled("img")(() => ({
  width: 25,
}));

export const Brand = styled("span")(() => ({
  letterSpacing: "0.125em",
  marginLeft: 10,
  marginRight: "auto",
}));
