import * as React from 'react';
import {
  Typography,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow
} from '@mui/material';
import { useRecordContext } from 'react-admin';

const InquiriesList = () => {
  const item = useRecordContext();
  return (
    <>
    {item.inquiries.length === 0 ? (
      <>
        <Typography variant="body2" sx={{ mt: 1, mb: 2, lineHeight: "1.25em" }}>
          No inquiries found.
        </Typography>
        <Divider sx={{ mb: 2 }} />
      </>
    ) : (
      <TableContainer sx={{ width: '100%', mb: 2 }}>
        <Table size="small" padding="none">
          <TableHead>
            <TableRow>
              <TableCell sx={{ pb: 1 }}>Inquiry</TableCell>
              <TableCell align="right" sx={{ pb: 1 }}>Estimate</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {item.inquiries.map((inquiry: any) => (
              <TableRow key={inquiry.id}>
                <TableCell sx={{ py: 1 }}>
                <strong>{inquiry.category.name}</strong> - {inquiry.name}
                  {inquiry.comment && (
                    <>
                      <br />
                      <small><strong>Service Request:</strong> {inquiry.comment}</small>
                    </>
                  )}
                </TableCell>
                <TableCell align="right" valign="top" sx={{ py: 1 }}>
                  {inquiry.formatted_estimate}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      )}
    </>
  )
}

export default InquiriesList;
