// in posts.js
import React from 'react'
import { ReferenceField, Edit as AdminEdit, TextField, SimpleForm, EditProps, useRecordContext } from 'react-admin'

const Edit = (props: EditProps) => {
  const Title = () => {
    const record = useRecordContext();
    return <span>{record.name}</span>
  }
  return (
    <AdminEdit title={<Title />} {...props}>
      <SimpleForm>
        <ReferenceField label="Item Type" source="repairable_id" reference="repairables" link={"edit"}>
          <TextField source="name" />
        </ReferenceField>
        <TextField source="name" />
        <TextField source="order" />
      </SimpleForm>
    </AdminEdit>
  )
}

export default Edit
