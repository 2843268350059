import React from "react";
import {
  ReferenceInput,
  SelectInput,
  Datagrid,
  TextField,
  ReferenceField,
  FunctionField,
  NumberInput,
  Edit as AdminEdit,
  SimpleForm,
  ReferenceManyField,
  RichTextField,
  TopToolbar,
  RaRecord,
  useRecordContext
} from "react-admin";
import { RichTextInput } from "ra-input-rich-text"
import Button from "@mui/material/Button";
import { Done, Clear } from "@mui/icons-material";
import { format } from 'date-fns'

import env from "src/env";
import PriceField from "src/fields/PriceField";
import MonetaryField from "src/fields/MonetaryField";
import SubtotalInput from "src/inputs/orders/SubtotalInput";
import ShippingFeeInput from "src/inputs/orders/ShippingFeeInput";
import CuteBooleanField from "src/components/CuteBooleanField";

const Actions = ({ data }: any) => {
  if (!data?.id) return null;
  const openReceiptShippingLabel = () => {
    window.open(data?.receipt_shipment.label_url, "_blank");
  };
  const openReturnShippingLabel = () => {
    window.open(data?.return_shipment.label_url, "_blank");
  };
  return (
    <TopToolbar>
      <Button
        color="primary"
        variant="contained"
        href={`${env.API_URL}/rest/orders/${data.id}.png`}
        target="_blank"
      >
        Print Customer Ticket
      </Button>
      &nbsp;
      <Button
        color="primary"
        variant="outlined"
        href={`${env.API_URL}/rest/orders/${data.id}.pdf`}
        target="_blank"
      >
        Print Packing Slip
      </Button>
      {data?.receive_method === "shipping_label" && data?.status === "pending_receipt" && (
        <Button
          color="primary"
          variant="outlined"
          onClick={openReceiptShippingLabel}
          style={{ marginLeft: 5 }}
        >
          <>Print Customer Shipping Label</>
        </Button>
      )}
      {data?.status === "pending_return" && (
        <Button
          color="primary"
          variant="outlined"
          onClick={openReturnShippingLabel}
          style={{ marginLeft: 5 }}
        >
          <>Print Return Shipping Label</>
        </Button>
      )}
    </TopToolbar>
  );
};

export const Edit = (props: any) => {
  const Title = () => {
    const record = useRecordContext();
    return <span>{record?.confirmation_code}</span>
  }
  return (
    <AdminEdit
      title={<Title />}
      {...props}
      actions={<Actions />}
    >
      <SimpleForm>
        <TextField source="confirmation_code" label="Ticket #" />
        <ReferenceField
          label="Customer"
          source="user_id"
          reference="users"
          link={"edit"}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceInput
          label="Storefront"
          source="storefront_id"
          reference="storefronts"
        >
          <SelectInput optionText={(storefront) => storefront.name} />
        </ReferenceInput>
        <FunctionField
          source="receive_method"
          render={(record: RaRecord) => record.receive_method.titleize()}
        />
        <FunctionField
          source="return_method"
          render={(record: RaRecord) => record.return_method.titleize()}
        />
        <FunctionField
          source="scheduled_at"
          render={(record: RaRecord) => {
            if (['standard_courier', 'ondemand_courier'].includes(record.receive_method)) {
              const start = new Date(record.scheduled_at);
              if (record.receive_method === 'standard_courier') {
                return format(new Date(start), "MMM d, yyyy");
              } else {
                const end = new Date(start);
                end.setHours(end.getHours() + 2);
                return `${format(start, "MMM d, yyyy HH:mm ")} - ${format(end, 'HH:mm ')}`;
              }
            } else {
              return "Not a pick up order";
            }
          }} />
        <FunctionField
          source="status"
          render={(record: RaRecord) => record.status.titleize()}
        />
        <PriceField source="subtotal" />
        <FunctionField
          label="Is rush"
          render={(record: RaRecord) =>
            record.rush_fee ? (
              <Done color="error" />
            ) : (
              <Clear color="disabled" />
            )
          }
        />
        <SubtotalInput source="price_override" />
        <ShippingFeeInput source="roundtrip_fee" />
        <NumberInput source="rack_number" min="1" step="1" />
        <FunctionField
          label="Service Request"
          style={{ width: '100%' }}
          render={(record: RaRecord) =>
            ["pending_receipt", "received"].includes(record.status) ? (
              <RichTextInput
                label=""
                source="customer_note"
                style={{ width: '100%' }}
              />
            ) : (
              <RichTextField source="customer_note" />
            )
          } />
        <ReferenceManyField
          label="Estimates"
          reference="order_items"
          target="order_id"
          sort={{ field: "created_at", order: 'DESC' }}
        >
          <Datagrid>
            <ReferenceField
              label="Estimate"
              source="id"
              reference="order_items"
              link={"edit"}
            >
              <FunctionField
                label="Item"
                render={(record: RaRecord) => record.formatted_description}
              />
            </ReferenceField>
            <ReferenceField
              label="Order"
              source="order_id"
              reference="orders"
              link={"edit"}
            >
              <FunctionField
                render={(record: RaRecord) => record.confirmation_code}
              />
            </ReferenceField>
            <MonetaryField label="Est. Val." source="estimated_value" />
            <TextField source="formatted_estimate" />
            <TextField source="additional_information" />
            <CuteBooleanField label="Service Request?" source="customer_note" />
            <CuteBooleanField label="Rush?" source="is_rush" />
            <CuteBooleanField label="Needs Consultation?" source="needs_consultation" />
          </Datagrid>
        </ReferenceManyField>
      </SimpleForm>
    </AdminEdit>
  );
};

export default Edit;
