import React from "react";
import {
  useRecordContext,
  useShowContext
} from "react-admin";
import ItemsList from "./ItemsList";

const RepairTabContent = () => {
  const record = useRecordContext();
  const { isLoading } = useShowContext();
  if (isLoading) return null;
  return (
    <>
      <ItemsList orderStatus={record.status} orderId={record.id} />
    </>
  );
};

export default RepairTabContent;
