import * as React from 'react';
import { WrapperField, FieldProps, useRecordContext } from 'react-admin';
import {
  Typography,
  Link
} from '@mui/material';

const ReceiptTrackingNumberField = (props: FieldProps) => {
  const record = useRecordContext();
  const shipment = record.receipt_shipment
  if (shipment?.tracking_id) {
    return (
      <WrapperField>
        <div style={{ display: 'flex' }}>
          <Typography variant="body2">
            {shipment.tracking_id}{' '}
            <small>
              <Link href={`https://www.ups.com/track?loc=en_US&tracknum=${shipment.tracking_id}&requester=WT/trackdetails`} color="secondary">
                Track via {shipment.provider === 'ups' ? 'UPS' : (<span style={{ textTransform: 'capitalize' }}>{shipment.provider}</span>)}
              </Link>
            </small>
          </Typography>
        </div>
      </WrapperField>
    )
  } else {
    return <Typography variant="body2">No tracking number</Typography>
  }
}

export default ReceiptTrackingNumberField;

