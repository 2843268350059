import React from 'react';
import { Grid } from '@mui/material';
import BinList from './BinList';
import SortingCard from './SortingCard';
import { Title } from 'react-admin';
import { SortingScreenProvider } from 'src/providers/sortingScreen';

function SortingPage() {
  return (
    <SortingScreenProvider>
      <Title title="Item Assembly" />
      <Grid container sx={{ height: 'calc(100vh - 96px)' }}>
        <Grid item xs={4} height="100%">
          <BinList />
        </Grid>
        <Grid item xs={8} maxHeight="100%">
          <SortingCard />
        </Grid>
      </Grid>
    </SortingScreenProvider>
  )
}

export default SortingPage
