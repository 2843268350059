import * as React from 'react'
import {
  useRecordContext,
  useRefresh,
  useNotify
} from 'react-admin'
import {
  Button, SxProps,
} from '@mui/material'
import env from 'src/env'

type EventButtonProps = {
  resource: string
  buttonLabel: string | JSX.Element
  event: string
  color?: 'primary' | 'secondary' | 'error'
  sx?: SxProps
}

const EventButton = (props: EventButtonProps) => {
  const token = localStorage.getItem(env.TOKEN_KEY)
  const record = useRecordContext()
  const refresh = useRefresh()
  const notify = useNotify()
  const [isSubmitting, setSubmitting] = React.useState(false)
  const handleClick = async () => {
    setSubmitting(true)
    try {
      const response = await fetch(`${env.API_URL}/rest/${props.resource}/${record.id}/${props.event}`, {
        method: 'POST',
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      if (response.status >= 400) {
        const data = await response.json()
        const errArray = Object.entries(data.errors)[0]
        // @ts-ignore
        const error = errArray[0].titleize() + ' ' + errArray[1][0]
        notify(`Error: ${error}.`, { type: 'warning' })
      } else {
        notify('Successfully updated')
      }
    } catch (error) {
      notify('Server error. Contact the developer', { type: 'error' })
      // TODO: log error in sentry
    } finally {
      setSubmitting(false)
      refresh()
    }
  }
  return (
    <Button
      size="small"
      color={props.color ?? "secondary"}
      variant={!props.color || props.color === 'secondary' ? 'contained' : 'outlined'}
      fullWidth
      onClick={handleClick}
      disabled={isSubmitting}
      sx={props.sx}
    >
      {props.buttonLabel}
    </Button>
  )
}

export default EventButton
