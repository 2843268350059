import * as React from 'react';
import { ReferenceField, TextField, WrapperField, SelectInput, ReferenceInput, useRecordContext, Labeled, useShowContext } from 'react-admin';
import {
  Typography,
  Box,
  Grid,
} from '@mui/material';
import ReceiveMethodField from "src/components/resources/orders/ReceiveMethodField";
import ReturnMethodField from "src/components/resources/orders/ReturnMethodField";
import ReceiptTrackingNumberField from 'src/components/resources/orders/ReceiptTrackingNumberField';
import ReturnTrackingNumberField from 'src/components/resources/orders/ReturnTrackingNumberField';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import EditDialog from 'src/components/EditDialog';
import RackingNumberField from './RackingNumberField';

const AddStoreDialog = () => {
  const record = useRecordContext();
  const { isLoading } = useShowContext();

  if (isLoading) return null;

  const label = "Add storefront";
  const description = "Choose the LEATHER SPA location where the customer can pick up their item(s)."

  return (
    <EditDialog record={record} resource="orders" label={label} description={description} buttonColor="error">
      <ReferenceInput
        label="Storefront"
        source="storefront_id"
        reference="storefronts"
      >
        <SelectInput optionText={(storefront) => storefront.name} fullWidth />
      </ReferenceInput>
    </EditDialog>
  )
}

const CustomerAddress = () => {
  const record = useRecordContext();
  return (
    <Typography variant="body2">
      <span>{record.name}</span><br />
      {record.attention_name && <span>Attention: {record.attention_name}</span>}<br />
      {record.company_name && <span>Company: {record.company_name}</span>}<br />
      <span>{record.street}</span><br />
      {record.address_line2 && <span>{record.address_line2}</span>}<br />
      <span>{record.city}, {record.state_code} {record.postal_code}</span><br />
      <span>{record.phone_number}</span>
    </Typography>
  )
};

const labelStyle = { '& span': { fontWeight: 900 } }

const ShippingTabContent = () => {
  const record = useRecordContext();
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Labeled label="Storefront" sx={labelStyle}>
          <WrapperField>
            {record.storefront_id ? (
              <div style={{ display: 'flex' }}>
                <LocationCityIcon fontSize="small" color="disabled" />
                <Box sx={{ ml: 1 }}>
                  <ReferenceField record={record} label="Storefront" source="storefront_id" reference="storefronts" link={false}>
                    <TextField source="name" />
                  </ReferenceField>
                </Box>
              </div>
            ) : (
              <AddStoreDialog />
            )}
          </WrapperField>
        </Labeled>
      </Grid>
      <Grid item xs={6}>
        <Labeled label="Racking Number" sx={labelStyle}>
          <RackingNumberField />
        </Labeled>
      </Grid>
      <Grid item xs={6}>
        <Labeled label="Receipt Method" sx={labelStyle}>
          <ReceiveMethodField displayTime={true} />
        </Labeled>
      </Grid>
      <Grid item xs={6}>
        <Labeled label="Return Method" sx={labelStyle}>
          <ReturnMethodField />
        </Labeled>
      </Grid>
      <Grid item xs={6}>
        <Labeled label="Receipt Tracking Number" sx={labelStyle}>
          <ReceiptTrackingNumberField />
        </Labeled>
      </Grid>
      <Grid item xs={6}>
        <Labeled label="Return Tracking Number" sx={labelStyle}>
          <ReturnTrackingNumberField />
        </Labeled>
      </Grid>
      <Grid item xs={6}>
        <Labeled label="From Address" sx={labelStyle}>
          <ReferenceField
            source="from_address_id"
            reference="addresses"
            link={false}
            emptyText="No address"
          >
            <CustomerAddress />
          </ReferenceField>
        </Labeled>
      </Grid>
      <Grid item xs={6}>
        <Labeled label="Return Address" sx={labelStyle}>
          <>
            <ReferenceField
              source="return_address_id"
              reference="addresses"
              link={false}
              emptyText="No address"
            >
              <CustomerAddress />
            </ReferenceField>
            {record.signature_fee ? "Signature on delivery required" : null}
          </>
        </Labeled>
      </Grid>
    </Grid>
  );
};

export default ShippingTabContent;
