import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Link, Title, useDataProvider, useNotify } from 'react-admin'
import { RackingScreenProvider, useRackingContext } from 'src/providers/rackingScreen'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { Button, Card, CardContent, SxProps, Theme } from '@mui/material'
import Stepper, { Step, StepProps } from 'src/components/Stepper'
import { isEmpty } from 'lodash'
import env from 'src/env'
import dataProvider from 'src/providers/data'

// TODO! Should call store_in when store_in_at is null and is rackable or already racked.

// dupe
const cardSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  height: '100%',
  marginY: '1em',
  paddingX: '1em'
}

// dupe
const messageBoxStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  textAlign: 'center',
  height: '100%',
  width: '100%',
  padding: '2em'
}

// dupe
const buttonStyle: React.CSSProperties = {
  fontWeight: 600,
  textDecoration: 'none',
  borderWidth: 2,
  padding: '0.5em 1em',
  borderStyle: 'solid'
}

function StorefrontSelector({
  handleChange,
  storefrontId,
  storefronts
}: {
  handleChange: (event: SelectChangeEvent) => void
  storefrontId?: string
  storefronts: any[]
}) {
  return (
    <FormControl variant="filled" fullWidth>
      <InputLabel id="label" sx={{ paddingInline: 1 }}>
        Storefront
      </InputLabel>
      <Select labelId="label" defaultValue="" onChange={handleChange} fullWidth>
        <MenuItem value="">
          <em>Select a storefront</em>
        </MenuItem>
        {storefronts.map((storefront) => (
          <MenuItem key={storefront.id} value={storefront.id} selected={storefrontId === storefront.id}>
            {storefront.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

// select what storefront you want to operate on before continuing.
// storefront->ticket
function ChooseStorefrontStep(props: StepProps): Step {
  const [storefrontId, setStorefrontId] = useState<string>()
  const [storefronts, setStorefronts] = useState<any[]>([])
  const dataProvider = useDataProvider()

  useEffect(() => {
    const fetchStorefronts = async () => {
      let response
      try {
        response = await dataProvider.getList('storefronts', {
          pagination: { page: 1, perPage: 200 },
          sort: { field: 'created_at', order: 'DESC' },
          filter: {}
        })
        setStorefronts(response.data)
      } catch {
        setStorefronts([])
      }
    }
    fetchStorefronts()
  }, [])

  const handleChange = (event: SelectChangeEvent) => {
    setStorefrontId(event.target.value)
  }

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent sx={cardSx}>
        <h1>Select a storefront to begin racking</h1>
        <StorefrontSelector handleChange={handleChange} storefrontId={storefrontId} storefronts={storefronts} />
        <div style={{ marginTop: 20 }}>
          <Button onClick={props.next} size="large" variant="contained" color="secondary" disabled={storefrontId === undefined}>
            Confirm
          </Button>
        </div>
      </CardContent>
    </Card>
  )
}

// if store pickup and ready for racking, continue to rack step, else show details on this step.
// if already racked but not pending return, toggle pending return and show details.\
function ScanTicketStep(props: StepProps): Step {
  const inputRef = useRef<HTMLInputElement>(null)
  const {
    code, display, ticketQuery, setTicketQuery,
    orderItem, setOrderItem, searchOrderItem,
    rackNumber, setRackNumber
  } = useRackingContext()
  let timeout: ReturnType<typeof setTimeout>

  const handleChange = () => {
    if (inputRef.current) {
      clearTimeout(timeout)
      timeout = setTimeout(async () => {
        try {
          await searchOrderItem(inputRef.current!.value)
        } catch (err) {
          setOrderItem(undefined)
          if (inputRef.current) inputRef.current.value = ''
        }
      }, 500)
    }
  }

  const reset = () => {
    if (inputRef.current) inputRef.current.value = ''
    setTimeout(() => {
      setRackNumber(null)
      setOrderItem(undefined)
      setTicketQuery('')
    }, 500)
  }

  useEffect(() => {
    if (code === 'ready' && rackNumber === null) props.next()
  }, [code, rackNumber])

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent sx={{ ...cardSx, backgroundColor: display.backgroundColor, color: `${display.textColor} !important` }}>
        <input
          type="text"
          ref={inputRef}
          onChange={handleChange}
          autoFocus={true}
          onBlur={(e) => {
            e.preventDefault()
            const target = e.currentTarget as HTMLInputElement
            setTimeout(function () { target.focus() }, 5)
          }}
          style={{ opacity: 0.0, position: 'absolute', left: '-9999px' }}
        />
        <span style={{ fontSize: '3rem', fontWeight: 600 }}>Scan Ticket Step</span>
        {code === 'initial' ? (
          <div style={messageBoxStyle}>
            <span style={{ fontSize: '3rem', fontWeight: 600 }}>Start scanning ticket barcodes</span>
          </div>
        ) : code === 'not_found' ? (
          <div style={{ textAlign: 'center' }}>
            <h1 style={{ fontSize: '3rem', marginBottom: '0.2em' }}>
              <>Could not find: {ticketQuery}</>
            </h1>
            <Button variant="contained" color="secondary" size="large" onClick={reset}>
              Try Again
            </Button>
          </div>
        ) : (
          <div style={messageBoxStyle}>
            <span style={{ fontSize: '3rem', fontWeight: 600 }}>{orderItem.code}</span>
            <span style={{ fontSize: '3rem', fontWeight: 600 }}>{display.message}</span>
            {rackNumber && <span style={{ fontSize: '3rem', fontWeight: 600 }}>Rack: {rackNumber}</span>}
            <span style={{ fontSize: '3rem', fontWeight: 600 }}>Return Method: {orderItem.order.return_method.titleize()}</span>
            <Link to={`/orders/${orderItem.order_id}/show`} target="_blank" style={buttonStyle}>
              Review Order
            </Link>
            <Button variant="contained" color="secondary" size="large" onClick={reset}>
              Next Ticket
            </Button>
          </div>
        )}
      </CardContent>
    </Card>
  )
}

// ONLY REACH THIS STEP IF CODE READY.
function ScanRackStep(props: StepProps): Step {
  const token = localStorage.getItem(env.TOKEN_KEY)
  const notify = useNotify()
  const inputRef = useRef<HTMLInputElement>(null)
  const { display, rackNumber, setRackNumber, orderItem, setOrderItem } = useRackingContext()
  const shouldBeginReturn = useMemo(() => orderItem.order.status === 'repaired', [orderItem])

  let timeout: ReturnType<typeof setTimeout>

  const handleChange = () => {
    if (inputRef.current) {
      const newRackNumber = inputRef.current.value
      clearTimeout(timeout)
      timeout = setTimeout(async () => {
        if (rackNumber) {
          await beginReturn()
        } else {
          const rackNumberAssigned = await assignRackNumber(newRackNumber)
          if (rackNumberAssigned && shouldBeginReturn) await beginReturn()
        }
        if (inputRef.current) inputRef.current.value = ''
      }, 500)
    }
  }

  const assignRackNumber = async (rackNumber: string) => {
    let result = false
    try {
      const order = orderItem?.order
      await dataProvider.update('orders', {
        id: order.id,
        data: {
          rack_number: rackNumber
        },
        previousData: order
      })
      notify(`Rack number assigned to order ${order.confirmation_code}`)
      // refetch
      const { data } = await dataProvider.getOne('order_items', { id: orderItem.id })
      setOrderItem(data)
      result = true
    } catch (error) {
      notify('Server error. Contact the developer', { type: 'error' })
      // TODO: log error in sentry
    } finally {
      return result
    }
  }

  const beginReturn = async () => {
    try {
      const response = await fetch(`${env.API_URL}/rest/orders/${orderItem.order_id}/begin_return`, {
        method: 'POST',
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      })
      if (response.status >= 400) {
        const data = await response.json()
        const errArray = Object.entries(data.errors)[0]
        // @ts-ignore
        const error = errArray[0].titleize() + ' ' + errArray[1][0]
        notify(`Error: ${error}.`, { type: 'warning' })
      } else {
        notify('Customer notified for pickup')
      }
    } catch (error) {
      notify('Server error. Contact the developer', { type: 'error' })
    }
  }

  const goBack = () => {
    setRackNumber(null)
    setOrderItem(undefined)
    props.previous()
  }

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent sx={{ ...cardSx, backgroundColor: display.backgroundColor, color: `${display.textColor} !important` }}>
        <input
          type="text"
          ref={inputRef}
          onChange={handleChange}
          autoFocus={true}
          onBlur={(e) => {
            e.preventDefault()
            const target = e.currentTarget as HTMLInputElement
            setTimeout(function () { target.focus() }, 5)
          }}
          style={{ opacity: 0.0, position: 'absolute', left: '-9999px' }}
        />
        <div style={messageBoxStyle}>
          <span style={{ fontSize: '3rem', fontWeight: 600 }}>Scan Rack Step</span>
          <span style={{ fontSize: '3rem', fontWeight: 600 }}>Item: {orderItem.code}</span>
          {isEmpty(rackNumber)
            ? <h1>Scan rack barcode to assign order</h1>
            : <span style={{ fontSize: '3rem', fontWeight: 600 }}>Rack: {rackNumber}</span>}
          <span style={{ fontSize: '3rem', fontWeight: 600 }}>Return Method: {orderItem.order.return_method.titleize()}</span>
          {!isEmpty(rackNumber) && (
            <>
              <Link to={`/orders/${orderItem.order_id}/show`} target="_blank" style={{ ...buttonStyle }}>
                Review Order
              </Link>
              <Button variant="contained" color="secondary" size="large" onClick={goBack}>
                Next Ticket
              </Button>
            </>
          )}
        </div>
      </CardContent>
    </Card>
  )
}

function RackingPage() {
  const [step, setStep] = useState(0)
  return (
    <RackingScreenProvider>
      <Title title="Racking" />
      <Stepper
        step={step}
        setStep={setStep}
        steps={[ChooseStorefrontStep, ScanTicketStep, ScanRackStep]}
      />
    </RackingScreenProvider>
  )
}

export default RackingPage
