import * as React from 'react';
import { useNotify, useRefresh } from 'ra-core';
import { useGetIdentity } from 'react-admin';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { useDialog } from 'src/hooks/useDialog';
import dataProvider from 'src/providers/data';
import QualityControlMemoInput from 'src/inputs/orders/QualityControlMemoInput';

const CancelQuoteDialog = ({ quote }: { quote: any }) => {
  const { identity } = useGetIdentity()
  const refresh = useRefresh() // order_items
  const defaultValues = React.useMemo(() => ({
    reason: quote.reason,
    quality_control_memo: quote.quality_control_memo,
    performed_by_id: quote.performed_by_id
  }), [quote]);
  const { toggleDialog, DialogComponent } = useDialog();
  const { handleSubmit, control } = useForm({ defaultValues });
  const notify = useNotify();
  const isCanceled = quote.status === 'canceled';

  const onSubmit = async (data: any) => {
    try {
      await dataProvider.update('quotes', {
        id: quote.id,
        data: {
          ...data,
          assessed_at: new Date().toISOString(),
          assessed_by_id: identity?.id,
          performed_by_id: null,
          status: 'canceled'
        },
        previousData: defaultValues
      })
      notify(`Work canceled by ${identity?.fullName}`, { type: 'success' })
      refresh()
    } catch (error: any) {
      console.error(error)
      notify(`Error: ${error.message!}.`, { type: 'error' })
    } finally {
      toggleDialog()
    }
  };

  const handleToggleDialog = React.useCallback(() => {
    toggleDialog();
  }, [toggleDialog]);

  return (
    <>
      <Button size="small" variant={isCanceled ? "outlined" : "contained"} color="error" sx={{ minWidth: 'auto' }} onClick={handleToggleDialog}>
        {isCanceled ? 'Edit cancelation' : 'Cancel'}
      </Button>
      <DialogComponent>
        <DialogTitle>Cancel: {quote.category.titleize()} - {quote.name}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Indicate why the quote is canceled.
          </DialogContentText>
          <form onSubmit={handleSubmit(onSubmit)}>
            <QualityControlMemoInput control={control} />
            <DialogActions>
              <span style={{ flexGrow: 1 }} />
              <Button onClick={handleToggleDialog}>Dismiss</Button>
              <Button type="submit" variant="contained" color="error">Cancel Quote</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </DialogComponent>
    </>
  )
}

export default CancelQuoteDialog
