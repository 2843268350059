import * as React from "react";
import get from "lodash/get";
import Typography from "@mui/material/Typography";
import { TextFieldProps } from "react-admin";

const PriceField = ({
  className,
  source,
  record = {
    id: "",
  },
  ...rest
}: TextFieldProps) => {
  //@ts-ignore
  const value = get(record, source);
  let emptyText;
  if (record.status === "pending_receipt") {
    emptyText = "Confirm receipt before you can manage pricing.";
  } else {
    emptyText =
      "Specify repair services and/or give a price override (and return shipping fee if applicable) to see a price.";
  }
  return (
    <Typography
      component="span"
      variant="body2"
      className={className}
      {...rest}
    >
      {value ? `$${value.toFixed(2)}` : emptyText}
    </Typography>
  );
};

PriceField.defaultProps = {
  addLabel: true,
};

export default PriceField;
