import styles from './Login.module.css';
import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import {
  Button,
  Card,
  CircularProgress,
} from "@mui/material";
import { Notification, useTranslate, useLogin, useNotify } from "react-admin";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const translate = useTranslate();
  const notify = useNotify();
  const login = useLogin();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true)
    login({ email, password })
      .catch(() => notify('Invalid email or password'))
      .finally(() => setLoading(false))
  };

  return (
    <div className={styles.main}>
      <Card className={styles.card}>
        <div className={styles.cardHeader}>
          <img
            src="/ls-monogram.svg"
            alt="Leather Spa logo"
            className={styles.logo}
          />
          <h1 className={styles.heading}>Admin Dashboard</h1>
        </div>
        <form className={styles.form} onSubmit={handleSubmit} noValidate>
          <TextField
            name="email"
            type="email"
            value={email}
            label="Email address"
            variant="outlined"
            onChange={e => setEmail(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            name="password"
            type="password"
            value={password}
            label="Password"
            variant="outlined"
            onChange={e => setPassword(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Button
            className={styles.button}
            variant="contained"
            type="submit"
            color="secondary"
            disabled={loading}
            fullWidth
          >
            {loading && <CircularProgress size={25} thickness={2} />}
            {translate("ra.auth.sign_in")}
          </Button>
          <Notification />
        </form>
      </Card>
      <div style={{ flexGrow: 1 }}></div>
      <div>
        <img src="/Tannery-logo_Powered-by_White.png" style={{ marginTop: 50, maxWidth: 150 }} alt="Powered by Tannery" />
      </div>
      <div className={styles.disclaimer}>
        <small>Copyright © {new Date().getFullYear()} LEATHER SPA. All rights reserved.</small>
      </div>
    </div>
  );
};

export default Login;
