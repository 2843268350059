import * as React from 'react';
import { CreateBase, SaveButton, Form, ResourceContextProvider, RecordContextProvider, useRefresh, useNotify } from 'react-admin';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  SxProps,
} from '@mui/material';
import { MuiButtonVariants, MuiColors } from 'types';

type FormDialogProps = {
  resource: string;
  defaultValues?: any;
  label: JSX.Element | string;
  buttonVariant?: MuiButtonVariants;
  buttonColor?: MuiColors;
  description: string;
  children: React.ReactNode;
  sx?: SxProps
}

export default function CreateDialog(props: FormDialogProps) {
  const [isOpen, toggleOpen] = React.useState(false);
  const refresh = useRefresh();
  const notify = useNotify();

  const onSuccess = () => {
    refresh();
    notify('Record created successfully', { type: 'success' })
    toggleOpen(false)
  }

  const onError = (error: any) => {
    try {
      const errArray = Object.entries(error.body.errors)[0]
      // @ts-ignore
      const message = errArray[0].titleize() + ' ' + errArray[1][0]
      notify(`Error: ${message}.`, { type: 'error' })
    } catch (e) {
      notify('Error: Something went wrong. Contact a developer.', { type: 'error' })
    }
  }

  return (
    <ResourceContextProvider value={props.resource}>
      <RecordContextProvider>
        <Button size="small" variant={props.buttonVariant} color={props.buttonColor} onClick={() => toggleOpen(!isOpen)} sx={props.sx}>
          {props.label}
        </Button>
        <CreateBase resource={props.resource} redirect={false} mutationOptions={{ onError, onSuccess }}>
          <Dialog open={isOpen} onClose={() => toggleOpen(false)}>
            <Form defaultValues={props.defaultValues}>
              <DialogTitle>{props.label}</DialogTitle>
              <DialogContent>
                <DialogContentText sx={{ mb: 2 }}>
                  {props.description}
                </DialogContentText>
                {props.children}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => toggleOpen(false)}>Cancel</Button>
                <SaveButton />
              </DialogActions>
            </Form>
          </Dialog>
        </CreateBase>
      </RecordContextProvider>
    </ResourceContextProvider>
  );
}

CreateDialog.defaultProps = {
  buttonVariant: 'contained'
}
