import * as React from 'react'
import {
  useRecordContext,
  useShowContext,
  NumberInput
} from 'react-admin'
import {
  Card,
  CardContent,
  Box,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableFooter,
  CardHeader,
  IconButton,
  Divider,
  Typography
} from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import EditDialog from 'src/components/EditDialog'
import PendingStep from './PendingStep'
import CompletedStep from './CompletedStep'
import EventButton from './EventButton'
import formatCurrency from 'src/utilities/formatCurrency'

const Aside = () => {
  const order = useRecordContext()
  const { isLoading } = useShowContext()
  if (isLoading) return null

  return (
    <Box display={{ xs: 'none', md: 'block' }} width={400} sx={{ minWidth: 300 }}>
      <Box ml={2}>
        <Card>
          <CardHeader
            action={
              <IconButton href={`/#/users/${order.user_id}/show`} target="_blank" aria-label="customer">
                <OpenInNewIcon />
              </IconButton>
            }
            title={`${order.user.first_name} ${order.user.last_name}`}
            subheader={order.user.corporate_account_id ? 'Corporate Account' : order.user.is_vip ? 'VIP Customer' : 'Standard Customer'}
          />
          <CardContent sx={{ pt: 0 }}>
            {order.user.internal_note && (
              <>
                <Typography variant="h6" color="red" sx={{ mb: 2, lineHeight: "1.25em" }}>
                  <strong>Memo: {order.user.internal_note}</strong>
                </Typography>
                <Divider />
              </>
            )}
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ pl: 0 }}>
                    Price
                    {' '}
                    <EditDialog resource="orders" record={order} label="Edit" buttonVariant="text" buttonColor="secondary" description="Specify a custom price">
                      <NumberInput source="price_override" />
                    </EditDialog>
                  </TableCell>
                  <TableCell align="right" sx={{ pr: 0 }}>
                    {order.price_override ? (
                      <>
                        <span style={{ textDecoration: 'line-through', color: 'gray' }}>
                          {formatCurrency(order.price)}
                        </span>{' '}
                        {formatCurrency(order.price_override)}
                      </>
                    ) : formatCurrency(order.price)}
                  </TableCell>
                </TableRow>
                {order.is_rush && <TableRow>
                  <TableCell component="th" scope="row" sx={{ pl: 0 }}>
                    Rush Fee
                    {' '}
                    <EditDialog record={order} buttonVariant="text" buttonColor="secondary" resource="orders" label="Edit" description="If free, set to zero. Else, $40 per item.">
                      <NumberInput source="rush_fee" />
                    </EditDialog>
                  </TableCell>
                  <TableCell align="right" sx={{ pr: 0 }}>{formatCurrency(order.rush_fee)}</TableCell>
                </TableRow>}
                {order.signature_fee != null && <TableRow>
                  <TableCell component="th" scope="row" sx={{ pl: 0 }}>Delivery Signature</TableCell>
                  <TableCell align="right" sx={{ pr: 0 }}>{order.signature_fee === 0 ? 'FREE' : formatCurrency(order.signature_fee)}</TableCell>
                </TableRow>}
                {order.insurance_fee != null && <TableRow>
                  <TableCell component="th" scope="row" sx={{ pl: 0 }}>Additional Insurance</TableCell>
                  <TableCell align="right" sx={{ pr: 0 }}>{order.insurance_fee === 0 ? 'FREE' : formatCurrency(order.insurance_fee)}</TableCell>
                </TableRow>}
                {order.roundtrip_fee ? <TableRow>
                  <TableCell component="th" scope="row" sx={{ pl: 0 }}>Roundtrip Fee</TableCell>
                  <TableCell align="right" sx={{ pr: 0 }}>{formatCurrency(order.roundtrip_fee)}</TableCell>
                </TableRow> : <TableRow>
                  <TableCell component="th" scope="row" sx={{ pl: 0 }}>
                    Return Fee
                    {' '}
                    <EditDialog resource="orders" record={order} label="Edit" buttonVariant="text" buttonColor="secondary" description="Specify a return fee">
                      <NumberInput source="return_fee" />
                    </EditDialog>
                  </TableCell>
                  <TableCell align="right" sx={{ pr: 0 }}>{formatCurrency(order.return_fee)}</TableCell>
                </TableRow>}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ py: 1, pl: 0 }}>
                    Subtotal
                  </TableCell>
                  <TableCell align="right" sx={{ pr: 0 }}>
                    {formatCurrency(order.subtotal)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ py: 1, pl: 0 }}>
                    <strong>Total</strong>
                  </TableCell>
                  <TableCell align="right" sx={{ pr: 0 }}>
                    <strong>{formatCurrency(order.total)}</strong>
                  </TableCell>
                </TableRow>
                {order.amount_paid > 0 && <TableRow>
                  <TableCell component="th" scope="row" sx={{ py: 1, pl: 0 }}>
                    Amount Paid
                  </TableCell>
                  <TableCell align="right" sx={{ pr: 0 }}>
                    {formatCurrency(order.amount_paid)}
                  </TableCell>
                </TableRow>}
                {order.amount_due >= 0 && <TableRow>
                  <TableCell component="th" scope="row" sx={{ py: 1, pl: 0 }}>
                    Amount Due
                  </TableCell>
                  <TableCell align="right" sx={{ pr: 0 }}>
                    {formatCurrency(order.amount_due)}
                  </TableCell>
                </TableRow>}
              </TableFooter>
            </Table>
          </CardContent>
        </Card>
        <Typography variant="h6" sx={{ fontSize: '1rem', fontWeight: 600, lineHeight: '1.25em', mt: 2.5 }}>
          Order History
        </Typography>
        <Stepper orientation="vertical" sx={{ mt: 0 }}>
          <StatusStep
            status="pending_return"
            pendingLabel="Awaiting customer receipt"
            completedLabel="Order returned"
            completedTimestamp="delivered_at"
          >
            <EventButton resource="orders" buttonLabel="Send feedback survey" event="confirm_return" />
          </StatusStep>
          <StatusStep
            status="repaired"
            pendingLabel="Ready to be scheduled for return delivery"
            completedLabel="Return delivery scheduled"
            completedTimestamp="outbound_at"
          >
            <EventButton resource="orders" buttonLabel="Schedule return delivery" event="begin_return" />
          </StatusStep>
          <StatusStep
            status="approved"
            pendingLabel="Awaiting completion of repairs"
            completedLabel="Repairs completed"
            completedTimestamp="completed_at"
          >
            <EventButton resource="orders" buttonLabel="Finish repair" event="finish_repair" />
          </StatusStep>
          <SortedItemsStep />
          <StatusStep
            status="invoiced"
            pendingLabel="Awaiting payment or approval"
            completedLabel="Customer paid/approved quote"
            completedTimestamp="approved_at"
          >
            <EventButton resource="orders" buttonLabel="Approve" event="approve" />
          </StatusStep>
          <StatusStep
            status="received"
            pendingLabel="Awaiting quote for cost of repairs"
            completedLabel="Initial quote sent"
            completedTimestamp="invoiced_at"
          >
            <EventButton resource="orders" buttonLabel="Publish quote" event="invoice" />
          </StatusStep>
          <StatusStep
            status="pending_receipt"
            pendingLabel="Awaiting receipt from customer"
            completedLabel="Order received"
            completedTimestamp="received_at"
          >
            <EventButton resource="orders" buttonLabel="Confirm receipt" event="confirm_receipt" />
          </StatusStep>
          <CompletedStep timestamp={order.created_at} label="Order created" last />
        </Stepper>
        {['pending_receipt', 'received', 'invoiced', 'approved'].includes(order.status) && (
          <>
            <Divider sx={{ my: 3 }} />
            <EventButton resource="orders" color="error" buttonLabel="Cancel order" event="cancel" sx={{ mb: 5 }} />
          </>
        )}
      </Box>
    </Box>
  )
}

const SortedItemsStep = (props: {}): JSX.Element => {
  const order = useRecordContext()
  const sortedItems = order.items.filter((i: any) => !!i.last_scanned_at)
  return (
    <>
      {sortedItems.map((item: any) => (
        <CompletedStep key={item.id} timestamp={item.last_scanned_at} label={`#${item.code} sorted.`} />
      ))}
    </>
  )
}

type StatusStepProps = {
  status: string
  pendingLabel: string
  children: React.ReactElement
  completedLabel: string
  completedTimestamp: string
}

const StatusStep = (props: StatusStepProps): JSX.Element => {
  const statuses: string[] = ['pending_receipt', 'received', 'invoiced', 'approved', 'repaired', 'pending_return', 'returned']
  const order = useRecordContext()
  if (statuses.indexOf(props.status) > statuses.indexOf(order.status)) {
    return <></>
  } else if (order.status === props.status) {
    return <PendingStep label={props.pendingLabel} sx={{ mt: 1 }}>
      {props.children}
    </PendingStep>
  } else {
    return <CompletedStep timestamp={order[props.completedTimestamp]} label={props.completedLabel} />
  }
}

export default Aside
