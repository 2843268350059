import { Menu as RaMenu } from 'react-admin';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export default function Menu() {
  return (
    <RaMenu>
      <RaMenu.ResourceItems />
      <RaMenu.Item to="/sorting" primaryText="Sorting" leftIcon={<ShuffleIcon />} />
      <RaMenu.Item to="/racking" primaryText="Racking" leftIcon={<CalendarViewMonthIcon />} />
      <RaMenu.Item to="/work-in" primaryText="Work In" leftIcon={<ArrowForwardIcon />} />
      <RaMenu.Item to="/work-out" primaryText="Work Out" leftIcon={<ArrowBackIcon />} />
    </RaMenu>
  )
};
