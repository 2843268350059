import React from 'react';
import {
  List as AdminList, Datagrid, Filter, TextField, SearchInput, ListProps, ListActionsProps, useListContext,
  CreateButton
} from 'react-admin';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import StarsIcon from '@mui/icons-material/Stars';
import WorkIcon from '@mui/icons-material/Work';
import CuteBooleanField from 'src/components/CuteBooleanField';
import QuickFilterButton from 'src/components/QuickFilterButton';

export const UserIcon = ConfirmationNumberIcon

type QuickFilterKeys = 'vip' | 'corporate' | undefined;

const filterMap = {
  vip: { is_vip: true },
  corporate: { corporate_account_id: true },
}

const ListActions = (props: ListActionsProps) => {
  const { setFilters } = useListContext();
  const [activeKey, setActiveKey] = React.useState<QuickFilterKeys>(undefined);

  React.useEffect(() => {
    if (activeKey) {
      setFilters(filterMap[activeKey], {})
    } else {
      setFilters({}, {})
    }
  }, [activeKey])

  return (
    <div style={{ minWidth: 500, textAlign: 'right' }}>
      <CreateButton
        variant="outlined" color="primary" sx={{ py: 0.586, marginBottom: '1em', whiteSpace: 'nowrap', lineHeight: 1.75 }}
        label="New Customer"
      />
      &nbsp;
      <QuickFilterButton title="VIP Customers" icon={<StarsIcon />} setActiveKey={setActiveKey} filterKey="vip" activeKey={activeKey} />
      &nbsp;
      <QuickFilterButton title="Corporate Account Customers" icon={<WorkIcon />} setActiveKey={setActiveKey} filterKey="corporate" activeKey={activeKey} />
    </div>
  )
}

export const List = (props: ListProps) => {
  return (
    <AdminList
      {...props}
      title="Customers"
      actions={<ListActions />}
      filters={(
        <Filter {...props}>
          <SearchInput source="q" alwaysOn />
        </Filter>
      )}
      sort={{ field: "created_at", order: 'DESC' }}
    >
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField label="Customer" source="name" />
        <TextField source="email" />
        <TextField source="phone_number" />
        <CuteBooleanField label="VIP" source="is_vip" />
        <CuteBooleanField label="Has note" source="internal_note" />
        <CuteBooleanField label="Corporate" source="corporate_account_id" />
      </Datagrid>
    </AdminList>
  )
}

export default List
