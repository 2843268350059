import * as React from 'react';
import { useRecordContext, TextInput, useInput } from 'react-admin';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import EditDialog from 'src/components/EditDialog';
import { MuiColors, MuiButtonVariants } from 'types';

const brands = [
  "Alexis Gamblin",
  "Aquazzura",
  "Balenciaga",
  "Botega Veneta",
  "Bruno Magli",
  "Chanel",
  "Chloe",
  "Christian Dior",
  "Christian Louboutin",
  "Fendi",
  "Gucci",
  "Hermes",
  "Hermès",
  "Isabel Marant",
  "Longchamp",
  "Louis Vuitton",
  "Manolo Blahnik",
  "Miu Miu ",
  "Oscar De La Renta",
  "Prada",
  "Rick Owens",
  "Saint Laurent",
  "Salvatore Ferragamo",
  "Stuart Weitzman",
  "Tom Ford",
  "Valentino",
  "YSL",
  "Yeezy",
  "Yves Saint Laurent"
].sort();

const colors = [
  "Beige",
  "Black",
  "Blue",
  "Bronze",
  "Brown",
  "Burgundy",
  "Cognac",
  "Floral",
  "Gold",
  "Green",
  "Grey",
  "Ivory",
  "Lemon",
  "Light",
  "Light brown",
  "Medium Brown",
  "Metallic",
  "Multi",
  "Navy",
  "Nude",
  "Orange",
  "Peach",
  "Pink",
  "Plaid",
  "Print",
  "Purple",
  "Red",
  "Silver",
  "Taupe",
  "Tan",
  "Turquoise",
  "White",
  "Yellow",
].sort();

const materials = [
  "Canvas",
  "Exotic",
  "Fabric",
  "Leather",
  "Logo Material",
  "Nubuck",
  "Patent",
  "Suede",
  "Satin"
].sort();

const conditions = [
  "Burnished finish",
  "Damaged laces",
  "Damaged ornament",
  "Damaged material",
  "Damaged lining",
  "Discolored",
  "Distressed",
  "Faded color",
  "Heels angle out",
  "No belt buckle",
  "No shoulder strap",
  "Oil stains",
  "Open seam",
  "Permanent stains",
  "Missing hardware",
  "Missing ornament",
  "Scratched",
  "Scratched hardware",
  "Stains",
  "Stains all over",
  "Water marks",
  "Wrinkled",
].sort();

const parts = [
  "Buckle",
  "Button",
  "Collar",
  "Counter",
  "Eyelet",
  "Footbed",
  "Glue",
  "Hardware",
  "Heel",
  "Insole",
  "Lace",
  "Lining",
  "Outsole",
  "Shaft",
  "Shoebox",
  "Snap",
  "Sole",
  "Stitching",
  "Strap",
  "Toe",
  "Tongue",
  "Upper",
  "Vamp",
  "Velcro",
  "Zipper",
  "Zipper pull",
  "Zipper teeth",
  "Zipper tape",
  "Zipper slider",
  "Zipper stop",
  "Zipper end",
  "Zipper chain"
].sort();

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function KeywordLists() {
  const record = useRecordContext();
  const [tab, setTab] = React.useState(0);
  const { field: { onChange, value } } = useInput({ source: 'internal_note' });

  const handleChange = (_event: React.SyntheticEvent, newTab: number) => {
    setTab(newTab);
  };

  const appendKeyword = (keyword: string) => {
    const newValue = value ? `${value} ${keyword}` : keyword;
    onChange(newValue);
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', padding: 'none' }}>
        <Tabs value={tab} onChange={handleChange} aria-label="Keyword lists">
          <Tab label="Brands" {...a11yProps(0)} />
          <Tab label="Colors" {...a11yProps(1)} />
          <Tab label="Materials" {...a11yProps(2)} />
          <Tab label="Condition" {...a11yProps(3)} />
          <Tab label="Parts" {...a11yProps(4)} />
        </Tabs>
      </Box>
      {[brands, colors, materials, conditions, parts].map((list, index) => (
        <TabPanel key={index} value={tab} index={index}>
          <Box sx={{ display: 'flex', gap: '4px', flexWrap: 'wrap' }}>
            {list.map((item, i) => (
              <Button key={i} variant="outlined" size="small" sx={{ textTransform: 'none' }} onClick={() => appendKeyword(item)}>
                {item}
              </Button>
            ))}
          </Box>
        </TabPanel>
      ))}
    </>
  )
}

export default function ItemMemoDialog() {
  const record = useRecordContext();
  const label = record.internal_note ? 'Edit memo' : 'Add memo';
  const description = 'Provide explanation of needed repairs for operations.';
  let buttonColor: MuiColors, buttonVariant: MuiButtonVariants;
  if (record.internal_note) {
    buttonColor = 'primary';
    buttonVariant = 'outlined';
  } else {
    buttonColor = 'error';
    buttonVariant = 'contained';
  }

  return (
    <EditDialog resource="order_items" record={record} label={label} description={description} buttonColor={buttonColor} buttonVariant={buttonVariant}>
      <KeywordLists />
      <TextInput label="Memo" source="internal_note" fullWidth multiline />
    </EditDialog>
  )
}
